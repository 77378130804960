import React, {useContext}from "react";
import UserContext from "../../UserContext";
import { Box, Typography, Button } from "@mui/material";
import AccordionTable from "../../Components/AccordionTable";
import DownloadIcon from "@mui/icons-material/Download";
import {BrandVendorPackMapping , VendorPack } from "../../vendorPack.js";


function Home() {

  const {user} = useContext(UserContext);
  const table = [
    {
      title: "Festival Operating Hours",
      rows: [

        ["December 08", "Doors open to public | Day 1" , "13:30 – 23:30"],
        ["December 09", "Doors open to public | Day 2" , "13:30 – 23:30"],
        ["December 10", "Doors open to public | Day 3" , "13:30 – 23:30"],
      ],
    },
    {
      title: "Submission Deadlines",
      rows: [
        ["Overdue", "Activation & Programming"],
        ["Overdue", "Design Concept"],
        ["October 17", "Technical Submission"],
        ["October 17", "DTCM Documentation (Entertainers Permit)"],
        ["October 24", "Permits & Licencing"],
        ["November 05", "Site Services"],
        ["November 14", "Site Access & Staff Accreditation"],
      ],
    },
    {
      title: "Build Milestones",
      rows: [
        ["December 07", "Installation complete ( no later than 07:00 )"],
        ["December 07", "Civil Defense (DCD) inspection"],
        ["December 08", "Final SOLE inspection"],
      ],
    },
    {
      title: "Load in/Build",
      rows: [
        ["5-day set up", "Anchor"],
        ["5-day set up", "Sponsor & Large Custom (including F&B) "],
        ["3-day set up", "Medium"],
        ["2-day set up", "Small"],
        ["2-day set up", "F&B"],
        ["1-day set up", "D3 Spotlight & Sneaker Swap"],
      ],
    },
    {
      title: "Load out/Teardown",
      rows: [
        ["December 11", "07:00 - 12:00: Product packing and debris clearance only."],
        [
          "December 11",
          "12:00 - 17:00: Removal of product, loose furniture, simple fittings and fixtures. Clearance of storage, dressing rooms, and support offices.",
        ],
        [
          "December 12",
          "07:00 - 17:00: Removal of hard furniture, kitchen equipment, impediments, cladding, wiring, Tech, A/V, storage units, dressing rooms, and office units.",
        ],
        [
          "December 14",
          "07:00 - 17:00: Removal of trussing, shading, rails, electrical, major structural components, and clearance of waste.",
        ],
      ],
    },
  ];

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{margin: {xs:"1rem" , md: "2rem 8rem"}, width:{xs:"90%", md:"60%"} }}  >
        <Typography variant="h6">Sole DXB is back!</Typography>
        <Typography variant="body1">
          Welcome to the 2023 festival, we’re glad to have you on board.{" "}
        </Typography>
        <Typography variant="body1">
          This year, accelerate your registration experience on a platform that
          does it all – from document submissions and accreditation, to ordering site
          service.
        </Typography>
        <Box sx={{ mt: 5 }}>
          {table.map((tabledata) => (
            <AccordionTable data={tabledata} />
          ))}
        </Box>
        {user?.company && 
        <Box
        sx={{margin: {xs:"1rem 0" , md:"5rem 0"}}}
        style={{
          width: "100%",
          
          display: "flex",
          flexDirection: "row",
          gap: 20,
        }}
      >
        <a href={VendorPack[BrandVendorPackMapping[user?.company]]} style={{textDecoration: "none"}} target="_blank" alt="Vendor Pack" >
        <Button
          variant="contained"
          style={{ color: "#FFF", backgroundColor: "#000" }}
          endIcon={<DownloadIcon />}
        >
          Vendor pack
        </Button>
        </a>
        {/* <Button
          variant="contained"
          style={{ color: "#FFF", backgroundColor: "#000" }}
          endIcon={<DownloadIcon />}
        >
          sustainability guide
        </Button>
        <Button
          variant="contained"
          style={{
            color: "#FFF",
            backgroundColor: "#000",
          }}
          endIcon={<DownloadIcon />}
        >
          layout
        </Button> */}
      </Box>
        }
        
      </Box>
     
    </Box>
  );
}

export default Home;
