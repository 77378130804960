import React from 'react'
import CartContext from "../CartContext";
import {
    Box,
    Typography,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    Button,
    ListSubheader,
  } from "@mui/material"; 

  

function Request({cost,index,type,d,title, dates}) {
    const {cart ,setCart } =  React.useContext(CartContext);
    const [values, setValues] = React.useState("");
  const [numOfHours, setNumOfHours] = React.useState(1);
  const [typeReq , setTypereq] = React.useState(false);
  const [block , setBlock] = React.useState([]);
//   React.useEffect(()=>{
//     if(block.length !==0)
//     setCart({...cart, [`${title} ${index}`]:block})
//   },[block])

  React.useEffect(()=>{
    if(typeReq && values) {
    let temp = {[d]:{cost: (cost[type][d] * numOfHours),date:values}};
    setCart({...cart, [`${title} ${index +1}`]:{...cart[`${title} ${index +1}`] , ...temp}})
}
// else if(!typeReq)
// {
//     const {[d] : tmp , ...rest } = cart[`${title} ${index +1}`];
//     setCart({...cart, [`${title} ${index +1}`]: rest})
// }

},[values,numOfHours,typeReq])

  
  const handleTypeReqSelect = (event) =>{
    setTypereq(event.target.checked)
  }
  
  const renderSelectGroup = (product,d) => {
    const items = product.val[d]?.map(p => {
      return (
        <MenuItem key={p} value={p}>
          {p}
        </MenuItem>
      );
    });
    return [<ListSubheader>{product.title}</ListSubheader>, items];
  };
  
  const handleHoursChange = (event) => {
    setNumOfHours(event.target.value);
  };

  const handleValChange = (event) => {
    setValues(event.target.value);
  };

  return (
   <>
   <Box sx={{display:"flex", flexDirection:{xs:"column", md:"row"}, alignItems:{xs:"flex-start", md:"center"} , justifyContent:"space-between"}}><FormControlLabel
      sx={{ mt: 2 }}
      control={<Checkbox checked={typeReq}  style={{color:"#000"}}  name={d} onChange={handleTypeReqSelect} value={d}/>}
      label={d}
        />
        <Typography>{cost?.[type]?.[d]?.toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
        </Box>
        <Box sx={{ display:"flex", gap:3}} >
          <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">{d === "1 Day" || d === "Extra Hour" ? "Date" : "Dates"}</InputLabel>
             <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values}
              disabled={!typeReq}
              name={d}
              label={d === "1 Day" || d === "Extra Hour" ? "Date" : "Dates"}
              onChange={handleValChange}
            >
              {dates.map((sel) =>  renderSelectGroup(sel,d)
                )}
              
            </Select>
          </FormControl>
        {d === "Extra Hour" &&
       
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">No of Hours</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // name={index}
            label="No of Hours"
            onChange={handleHoursChange}
          >
            {[1,2,3,4,5].map((d) => (
              <MenuItem value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl> }
        </Box>
   </>
  )
}

export default Request