import React from "react";
import CartContext from "../../CartContext";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Switch,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Badge,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RateCard from "../../Components/RateCard";
import RateCardSimple from "../../Components/RateCardSimple";
import {Loadservices, Securityservices, Cleaningservices , Fireservices ,Storageservices , Roomservices , Powerservices,Otherservices} from "./ServicesList.js";

import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const columns = [
  { field: 'Title', headerName: 'Title', width:550 },
  { field: 'Price', headerName: 'Price', width: 80 },

];

const rows = [
  { id: 1, Title: 'Entertainment permit (Per submission)', Price: 2500},
  { id: 2, Title: 'Rush fee (Late applicant)', Price: 4500},
  { id: 3, Title: 'Photography/videography | 1-day permit (Per submission)', Price: 500},
  { id: 4, Title: 'Photography/videography | 3-day permit (Per submission)', Price: 800},
  { id: 5, Title: 'Technical submission (Late applicant)', Price: 6800},
];




function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{width:'100%',position:"relative"}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 , width : "100  %"}}>
          {children}

        </Box>
      )}

    </div>
  );
}


function Services() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [sw , setSw] = React.useState({
    drone : false,
    technical : false,
    internet:false,
    pointOfSale : false,
    internationalShipping : false,
    warehousing : false,
    cleaningPackage1 : false,
    cleaningPackage2 : false,
    fb: false,
    kitchenEquipment:false,
    twinroom : false,
    crewID:false,
    vest:false,
  });
  const [value, setValue] = React.useState(0);
  const [droneRequirements, setDroneRequirements] = React.useState("");
  const [indernetRequirements, setInternetRequirements] = React.useState("");
  const [internationalShippingDetails, setinternationalShippingDetails] = React.useState("");
  const [technicalProductionRequirements, setTechnicalProdutionRequirements] = React.useState("");
  const [fnbRrequirements, setfnbRrequirements] = React.useState("");
  const [freezerRrequirements, setfreezerRrequirements] = React.useState("");
  const [permit, setPermit] = React.useState({

  });
  const [num, setNum] = React.useState(0);
  const {cart , setCart ,totalCost, cartSize} =  React.useContext(CartContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const pageCompleted = true;

  React.useEffect(()=>{
    setCart({...cart ,  "Drone Footage (Request Quote)" : { requirements : droneRequirements} });
  },[droneRequirements])
  React.useEffect(()=>{
    setCart({...cart ,  "Internet, data and telecom (Request Quote)" : { requirements : indernetRequirements} });
  },[indernetRequirements])
  React.useEffect(()=>{
    setCart({...cart ,  "TECHNICAL PRODUCTION AV (Request Quote)" : { requirements : technicalProductionRequirements} });
  },[technicalProductionRequirements])
  React.useEffect(()=>{
    setCart({...cart ,  "International Shipping" : { requirements : internationalShippingDetails} });
  },[internationalShippingDetails])
  React.useEffect(()=>{
    setCart({...cart ,  "Freezers" : { requirements : freezerRrequirements} });
  },[freezerRrequirements])

  React.useEffect(()=>{
    setCart({...cart ,  "F&B BAR OPERATIONS" : { requirements : fnbRrequirements} });
  },[fnbRrequirements])

  React.useEffect(()=>{
    setCart({...cart});
  },[])

  const handleNumChange = (event, price) =>{
      setNum({...num, [event.target.name]:event.target.value});
      setCart({...cart ,  [event.target.name] : {cost: price*event.target.value} });
  }
  const handleDroneSwitch = (event) => {
    setSw({...sw , drone : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handlePermitChange = (event, price) => {
    setPermit({...permit , [event.target.name] : event.target.checked});
    if(event.target.checked){
    setNum({...num,  [event.target.name] : 1 })
    setCart({...cart ,  [event.target.name] : {cost: price*num[event.target.name]} });
    }
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleTechnicalSwitch = (event) => {
    setSw({...sw , technical : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleInternetSwitch = (event) => {
    setSw({...sw , internet : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleRetailSwitch = (event) => {
    setSw({...sw , pointOfSale : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {cost:1800} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleInternationalShippingSwitch = (event) => {
    setSw({...sw , internationalShipping : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleWarehousingSwitch = (event) => {
    setSw({...sw , warehousing : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {cost:2000} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleCleaningPackageOneSwitch = (event) => {
    setSw({...sw , cleaningPackage1 : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {cost:3000} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleCleaningPackageTwoSwitch = (event) => {
    setSw({...sw , cleaningPackage2 : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {cost:3800} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handleFBSwitch = (event) => {
    setSw({...sw , fb : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handlekitchenEquipmentSwitch = (event) => {
    setSw({...sw , kitchenEquipment : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handletwinroomSwitch = (event) => {
    setSw({...sw , twinroom : event.target.checked});
    if(event.target.checked)
    setCart({...cart ,  [event.target.name] : {} });
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handlecrewIDSwitch = (event,price) => {
    setSw({...sw , crewID : event.target.checked});
    if(event.target.checked){
    setNum({...num,  [event.target.name] : 1 })
    setCart({...cart ,  [event.target.name] : {cost: price*num[event.target.name]} });
    }
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };
  const handlevestSwitch = (event,price) => {
    setSw({...sw , vest : event.target.checked});
    if(event.target.checked)
    {
    setNum({...num,  [event.target.name] : 1 })
    setCart({...cart ,  [event.target.name] : {cost: price*num[event.target.name]} });
    }
    else{
      const {[event.target.name] : tmp , ...rest} = cart;
      setCart(rest);
    }
  };

  if(!pageCompleted){
    return(<Box style={{padding:"5%", width:"80vw", gap:10,  display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}><Typography variant="h4">Exclusive Site Services</Typography>
    <Typography variant="body1">Site services must be procured exclusively through SOLE and designated
partners. Services include permits, drone footage, load in/out plant and
machinery, technical production AV, vendor security, cleaning, fire extinguishers
and smoke alarms, F&amp;B bar operations, storage rental, kitchen equipment and
power &amp; electrical. Please refer to Section 8.c in the Vendor Pack for full listing.</Typography>
    <Typography variant="body1">Book all your Site Service requirements online - coming soon.</Typography>
    </Box>);
  }


  return (
    <Box  sx={{ position: "relative" , padding: {xs: "1rem", md:"2rem 8rem"}}}>
      <div
        className="intro"
        sx={{
          width: {xs:"100%" , md:"60%"}
        }}
      >
        <Typography variant="h6">Exclusive Site Services</Typography>
        <Typography variant="body1">
        Site services must be procured exclusively through SOLE and designated partners. Services include permits, drone footage, load in/out plant and machinery, technical production AV, vendor security, cleaning, fire extinguishers and smoke alarms, F&B bar operations, storage rental, kitchen equipment and power and electrical.
        </Typography>
        <Typography variant="body1">
          <b>Note:</b>
        </Typography>
        <ul>
          <li>Use the site services form to place an order for your requirements.</li>
          <li>An estimate will be sent to you by email. 100% payment must be processed in advance.</li>
        </ul>
        <Typography variant="body1">
          <b>Deadline:</b>
        </Typography>
        <ul>
          <li>The deadline for your site services order is <b>November 05</b>.</li>
          <li>Submissions past the due date will incur a Rush Fee.</li>
        </ul>
        <Typography variant="h6" sx={{ mt: 3 }}>
          On-Site Services Order Form
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          (All rates are in AED and exclusive of VAT)
        </Typography>
      </div>
      <Box sx={{ position: {xs:"static", md:"fixed"}, bottom: 0, right: 0,width: "100%", color: "white", backgroundColor: "black",  display:"flex", alignItems:{xs:"flex-start", md:"flex-end"}, flexDirection:"column"}}>

      <IconButton component={Link} aria-label="cart" to="/checkout">
          <Badge badgeContent={cartSize ? cartSize : 0} color="primary">
          <ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <Typography
        style={{ fontWeight: "bold"}}
        variant="body1"
      >
        Total Cost : {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'AED' })}
      </Typography>
      </Box>
      {/* <Accordion style={{width:"70%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:"#000"}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Permits</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ height: 400, width: '90%' }}>

        <DataGrid
        style={{border:"none"}}
        rows={rows}
        columns={columns}
        checkboxSelection
      />
        </AccordionDetails>
      </Accordion>
      <FormControlLabel
        sx={{ mt: 2 }}
        labelPlacement="start"
        control={<Switch checked={droneChecked} style={{color:"#000"}} onChange={handleDroneSwitch} />}
        label="Drone Footage (Request Quote)"
      />
      {droneChecked && (
        <Box style={{ width: "60%" }} sx={{ mt: 1, mb: 2 }}>
          <TextField
            id="outlined-textarea"
            label="Multiline Placeholder"
            placeholder="Placeholder"
            multiline
            rows={7}
            fullWidth
          />
        </Box>
      )}
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
        Load In/Out Plant & Machinery
      </Typography>
      {services.map((service) => (
        <RateCard data={service} />
      ))} */}

<Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex',mb: '50px', flexDirection:{xs:"column", md:"row"}, width: "100%"}}
    >

      <Tabs
        orientation={matches? "vertical" : "horizontal"}

        variant="scrollable"
        scrollButtons="auto"
            allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: {xs:0 , md: 1}, borderColor: 'divider', width:{xs:"100%", md:"20%"}, textAlign:"left", alignItems:{xs:"center", md:"flex-start"}}}
      >
        <Tab label="PERMITS" {...a11yProps(0)} />
        <Tab label="DRONE FOOTAGE" {...a11yProps(1)} />
        <Tab label="LOAD IN/OUT PLANT & MACHINERY" {...a11yProps(2)} />
        <Tab label="TECHNICAL PRODUCTION AV" {...a11yProps(3)} />
        <Tab label="RETAIL & OPERATIONS" {...a11yProps(4)} />
        <Tab label="SECURITY" {...a11yProps(5)} />
        <Tab label="CLEANING" {...a11yProps(6)} />
        <Tab label="FIRE EXTINGUISHERS & SMOKE ALARMS" {...a11yProps(7)} />
        <Tab label="F&B OPERATIONS" {...a11yProps(8)} />
        <Tab label="STORAGE RENTAL" {...a11yProps(9)} />
        <Tab label="KITCHEN EQUIPMENT" {...a11yProps(10)} />
        <Tab label="GREEN ROOMS & TRAILERS" {...a11yProps(11)} />
        <Tab label="POWER & ELECTRICAL" {...a11yProps(12)} />
        <Tab label="OTHER" {...a11yProps(13)} />
      </Tabs>

      <TabPanel value={value} index={0}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Permit
          </Typography>
        <Box sx={{ height: 400, width: {xs:400, md:1000 }}}>
          {rows.map(row=><Box key={row.id} className="container" sx={{display:"flex", flexWrap:"wrap", flexDirection:{xs:"column", md:"row"},   alignItems: {xs:"flex-start", md:"center"}, justifyContent: "space-between" , width:"100%", gap:{xs:1, md:10}}}>

        <FormControl sx={{ m: 1, }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
          sx={{width:{xs:"300px", md:"500px"}}}
            control={
              <Checkbox checked={permit?.[row?.Title]} onChange={(e)=>handlePermitChange(e, row?.Price)} name={row.Title} />
            }
            label={row.Title}
          />
          </FormGroup>
      </FormControl>
          <Typography>{row.Price.toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
          <FormControl sx={{width:200}}>
          <InputLabel id="demo-simple-select-label">No of Permits</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled={!permit?.[row?.Title]}
            label="No of Hours"
            value={num?.[row.Title]}
            name={row.Title}
            onChange={(e)=>handleNumChange(e,row?.Price)}
          >
            {[1,2,3,4,5].map((d) => (
              <MenuItem value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl>
          </Box>)}

        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Drone
          </Typography>
      <FormControlLabel
            control={
              <Checkbox checked={sw.drone} onChange={handleDroneSwitch} name="Drone Footage (Request Quote)" />
            }
          label={"Drone Footage (Request Quote)"}
        />

      {sw.drone && (
        <Box  sx={{ mt: 1, mb: 2, width:{xs:"100%", md:"60%"} }}>
          <TextField
            id="outlined-textarea"
            label="Please share requirement details for a quotation"
            multiline
            rows={7}
            fullWidth
            value={droneRequirements}
            onChange={(e)=>setDroneRequirements(e.target.value)}
          />
        </Box>
      )}
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
        Load In/Out Plant & Machinery
      </Typography>
      {Loadservices.map((service) => (
        <RateCard data={service} />
      ))}
      </TabPanel>
      <TabPanel value={value} index={3}>

          <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox checked={sw.technical} style={{color:"#000"}} onChange={handleTechnicalSwitch} name="TECHNICAL PRODUCTION AV (Request Quote)"/>}
            label="Technical Production AV (Request Quote)"
          />
          {sw.technical && (
            <Box sx={{ mt: 1, mb: 2,  width:{xs:"100%", md:"60%"} }}>
              <TextField
                id="outlined-textarea"
                label="Please share tech specs and rider for a quotation"
                multiline
                rows={7}
                value={technicalProductionRequirements}
                onChange={(e)=>setTechnicalProdutionRequirements(e.target.value)}
                fullWidth
              />
            </Box>
          )}
            <Box sx={{ mt: 1, mb: 2,  width:{xs:"100%", md:"60%"} }}>
              <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox checked={sw.internet} style={{color:"#000"}} onChange={handleInternetSwitch} name="Internet, data and telecom (Request Quote)" />}
            label="Internet, data and telecom (Request Quote)"
          />

          {sw.internet &&

                <TextField

                  id="outlined-textarea"
                  label="Please share details per connection, per room"
                  multiline
                  value={indernetRequirements}
                  onChange={(e)=>setInternetRequirements(e.target.value)}
                  rows={7}
                  fullWidth
                />
          }
            </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
      Retail & Operations

          </Typography>
          <Box sx={{display:"flex", justifyContent:"space-between", alignItems: "center", width:{xs:"100%",md:"80%"}}}>
          <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox checked={sw.pointOfSale} style={{color:"#000"}} onChange={handleRetailSwitch} name="Point of sale"/>}
            label="Point Of Sale Card Terminals
            Per unit, +3% per transaction bank fee"
          />
          <Typography sx={{mt:2}}>{(1800).toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
          </Box>

            <Box sx={{ mt: 1, mb: 2,  width:{xs:"100%", md:"80%"} }}>
              <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox checked={sw.internationalShipping} style={{color:"#000"}} onChange={handleInternationalShippingSwitch} name="International Shipping" />}
            label="International Shipping (Request Quote)"
          />

          {sw.internationalShipping &&

                <TextField
                  id="outlined-textarea"
                  label="Please share details of your requirements for a quotation"
                  multiline
                  value={internationalShippingDetails}
                  onChange={(e)=>setinternationalShippingDetails(e.target.value)}
                  rows={7}
                  fullWidth
                />
          }

            </Box>
            <Box style={{ width: "80%" }} sx={{ mt: 1, mb: 2 }}>
              <Box sx={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
              <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox checked={sw.warehousing} style={{color:"#000"}} onChange={handleWarehousingSwitch} name="Wearhousing" />}
            label="Warehousing | Weekly"
          />
          <Typography>{(2000).toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
          </Box>
          <Typography variant="body1">
          Cost per sqm per week*, includes manpower and transfer to site
          </Typography>

            </Box>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
      Security (*12-hour Shift)
          </Typography>
      {Securityservices.map((service) => (
        <RateCardSimple  data={service} staff={true} />
      ))}
      </TabPanel>
      <TabPanel value={value} index={6}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Cleaning
          </Typography>
        {Cleaningservices.map((service) => (
        <RateCardSimple  data={service} staff={true} />
      ))}
      <Box>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems: "center", width:"70%"}}>
      <FormControlLabel
      sx={{ mt: 2 }}
      control={<Checkbox checked={sw.cleaningPackage1} style={{color:"#000"}} onChange={handleCleaningPackageOneSwitch} name="Cleaning Package One day" />}
      label="Cleaning Essentials Package | 1 DAY"
        />
        <Typography>{(3000).toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
        </Box>
        <Typography variant="body1">
        Includes vacuum cleaner, mops, carpet brushes, glass cleaner, dustpans, and tissue rolls
        </Typography>
      </Box>
      <Box>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems: "center", width:"70%"}}>
        <FormControlLabel
      sx={{ mt: 2 }}
      control={<Checkbox checked={sw.cleaningPackage2} style={{color:"#000"}} onChange={handleCleaningPackageTwoSwitch} name="Cleaning Package Three days" />}
      label="Cleaning Essentials Package | 3 DAYS"
        />
        <Typography>{(3800).toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
        </Box>
         <Typography variant="body1">
         Includes vacuum cleaner, mops, carpet brushes, glass cleaner, dustpans, and tissue rolls
        </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={7}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Fire Extinguishers and Smoke Alarms
          </Typography>
        {Fireservices.map((service) => (
        <RateCardSimple  data={service} />
      ))}


      </TabPanel>

      <TabPanel value={value} index={8}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       F&B Bar Operations

          </Typography>

        <FormControlLabel
      sx={{ mt: 2,  width:{xs:"100%", md:"60%"} }}
      control={<Checkbox checked={sw.fb} style={{color:"#000"}} onChange={handleFBSwitch} name="F&B" />}
      label="F&B Bar Operations (Request Quote)"
        />
        {sw.fb &&  <TextField
                  id="outlined-textarea"
                  label="Please share details of your requirements for a quotation"
                  multiline
                  value={fnbRrequirements}
                  onChange={e=>setfnbRrequirements(e.target.value)}
                  rows={7}
                  fullWidth
                />}
      </TabPanel>
      <TabPanel value={value} index={9}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Storage Rental
          </Typography>
        {Storageservices.map((service) => (
        <RateCardSimple  data={service} />
      ))}


      </TabPanel>
      <TabPanel value={value} index={10}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Kitchen Equipment
          </Typography>
      <FormControlLabel
      sx={{ mt: 2 }}
      control={<Checkbox checked={sw.kitchenEquipment} style={{color:"#000"}} onChange={handlekitchenEquipmentSwitch} name="Freezers"/>}
      label="Cold Rooms or freezers"
        />
        {sw.kitchenEquipment &&  <TextField
                  id="outlined-textarea"
                  label="Please share details of your requirements for a quotation"
                  multiline
                  value={freezerRrequirements}
                  onChnage={e=>setfreezerRrequirements(e.target.value)}
                  rows={7}
                  fullWidth
                />}
      </TabPanel>
      <TabPanel value={value} index={11}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Green Room and Trailers
          </Typography>

         {Roomservices.map((service) => (
        <RateCardSimple  data={service} />
      ))}
      </TabPanel>
      <TabPanel value={value} index={12}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Power and Electrical
          </Typography>
      {Powerservices.map((service) => (
        <RateCardSimple  data={service} />
      ))}

      </TabPanel>
      <TabPanel value={value} index={13}>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
       Other
          </Typography>
      {Otherservices.map((service) => (
        <RateCardSimple  data={service} />
      ))}
      <Box sx={{display:"flex", flexDirection:{xs:"column", md:"row"}, alignItems: {xs:"flex-start",md:"center"}, justifyContent: "space-between" , gap:{xs:1,md:10}, mt: 4, mb: 4,  width:{xs:"100%", md:"70%"}}}>
      <FormControlLabel
      sx={{ mt: 2 , display:"block" ,  width:250}}
      control={<Checkbox checked={sw.crewID} style={{color:"#000"}} onChange={handlecrewIDSwitch} name="Crew ID" />}
      label="Additional Crew ID for Build"
        />
        <Typography>{(25).toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
          <FormControl sx={{width:200}}>
          <InputLabel id="demo-simple-select-label">No of Ids</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled={!sw.crewID}
            label="No of Hours"
            value={num?.["Crew ID"]}
            name={"Crew ID"}
            onChange={(e)=>handleNumChange(e,25)}
          >
            {[1,2,3,4,5].map((d) => (
              <MenuItem value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl>

      </Box>
      <Box  sx={{display:"flex", flexDirection:{xs:"column", md:"row"}, alignItems: {xs:"flex-start",md:"center"}, justifyContent: "space-between" , gap:{xs:1,md:10}, mt: 4, mb: 4,  width:{xs:"100%", md:"70%"}}}>
        <FormControlLabel
      sx={{ mt: 2 , display:"block", width:250}}
      control={<Checkbox checked={sw.vest} style={{color:"#000"}} onChange={handlevestSwitch} name="Vest"/>}
      label="High Vis Vest for Build"
        />

<Typography>{(50).toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
          <FormControl sx={{width:200}}>
          <InputLabel id="demo-simple-select-label">No of Vest</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled={!sw.vest}
            label="No of Hours"
            value={num?.["Vest"]}
            name={"Vest"}
            onChange={(e)=>handleNumChange(e,50)}
          >
            {[1,2,3,4,5].map((d) => (
              <MenuItem value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl>

      </Box>
      </TabPanel>
      </Box>
    </Box>
  );
}

export default Services;
