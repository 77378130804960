import React from "react";
import CartContext from "../CartContext";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReqBlockSimple from "./ReqBlockSimmple";

function RateCardSimple({ data , staff }) {
  const { title, type, dates , cost, addText} = data;
  const {cart ,setCart } =  React.useContext(CartContext);
  const [req, setReq] = React.useState([{}]);
  const [selected , setSelected ] = React.useState(false);
  const [date , setDate ] = React.useState("");
  const [numOfStaff , setNumOfStaff ] = React.useState(1);

  const handleSelect = (event)=>{
    setSelected(event.target.checked)
  }
  const handleDateChange = (event)=>{
    setDate(event.target.value)
  }
  const handleStaffChange = (event)=>{
    setNumOfStaff(event.target.value)
  }

  React.useEffect(()=>{
    if(selected){
      const cartItem = {
        [title]:{
          date:date,
          cost: (cost * numOfStaff)
        }
      }
      setCart({...cart, ...cartItem})
    }
    else if(!selected){
      const {[title] : tmp , ...rest} = cart;
      setCart(rest);
    }
  },[numOfStaff,selected])
 


  return (
    <Box sx={{width:{xs:"100%", md:"70%"}}}>
      <Box sx={{display:"flex", flexDirection:{xs:"column", md:"row"}, alignItems:{xs:"flex-start",md:"center"}, justifyContent: "space-between", gap:1}}>
       <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox checked={selected} onChange={handleSelect}  style={{color:"#000"}}  name={title} />}
            label={title}
          />
          <Typography sx={{ mt: 1, mb: 1 }} variant="body1">
            {cost.toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}
          </Typography>
        </Box>
        {addText && <Typography sx={{ mt: 1, mb: 1 }} variant="body1">
            {addText}
          </Typography>}
        <Box sx={{display:"flex",flexDirection:{xs:"column", md:"row"},  gap:3}}>
        <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">{type === 1 ? "Date" : "Dates"}</InputLabel>
             <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={date}
              disabled={!selected}
              name={"Dates"}
              label={type === 1 ? "Date" : "Dates"}
              onChange={handleDateChange}
            >
              {dates.map((sel) =>  
              <MenuItem key={sel} value={sel}>
              {sel}
            </MenuItem>
                )}
              
            </Select>
          </FormControl>
          <FormControl sx={{width:"30%"}}>
             <InputLabel id="demo-simple-select-label">{staff ? "No of Staff" : "Qty"}</InputLabel>
             <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={numOfStaff}
              disabled={!selected}
              name={"No of staff"}
              label={staff ? "No of Staff" : "Qty"}
              onChange={handleStaffChange}
            >
              {[1,2,3,4,5].map((sel) =>  
              <MenuItem key={sel} value={sel}>
              {sel}
                </MenuItem>
                )}
              
            </Select>
          </FormControl>
        </Box>
    </Box>
  );
}

export default RateCardSimple;
