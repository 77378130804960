import React , {useState,useContext} from "react";
import CartContext from "../../../CartContext";
import UserContext from "../../../UserContext";
import axios from "axios";
import {Typography, Paper , TextField, Snackbar, Alert, Box, Button, InputAdornment , IconButton,FormControl, styled, TableContainer , Table , TableHead, TableRow, TableCell, TableBody } from "@mui/material";


function Checkout() {
 const {cart, setCart, totalCost} = useContext(CartContext);
 const {user} = useContext(UserContext);
 const ServicesInCart = Object.keys(cart);
 const [openLoginMessage, setopenLoginMessage] = React.useState(true)
  const [notificationMessage, setnotificationMessage] = React.useState("")
  const [notificationType, setnotificationType] = React.useState("")
  const {vertical , horizontal} = {vertical:"bottom", horizontal:"right"};
  const handleClose = () => {
    setopenLoginMessage(false);
  }


const email = user?.email;

const handleCheckout = () => {
  axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/service", { service:{...cart, tCost:totalCost} , email:email  }).then((res)=>{
  // axios.post("http://localhost:5081/user/service", { service:{...cart, tCost:totalCost} , email:email  }).then((res)=>{
      console.log(res);
      if(res.status === 200){
        setnotificationType("success");
        setnotificationMessage("Request submitted");
        setopenLoginMessage(true);
        return;
      }
    }).catch(error=>{
      console.log(error);
      setnotificationType("error");
      setnotificationMessage(error.message);
      setopenLoginMessage(true);
      return;
  }
    );

}

const handleRemove = (item) =>{
  const {[item] : tmp , ...rest} = cart;
      setCart(rest);
}

  return (
    <Box sx={{pt:"2rem", pl:{xs:"2rem", md:"8rem"},display:"flex", flexDirection:"column", gap:3}} >

      <Typography variant="h5" sx={{ml:2}}>Checkout : {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'AED' })} </Typography>

      {ServicesInCart.length == 0 ? <Typography variant="body1" sx={{ml:2}}>Add item to cart before checkout</Typography> :
        <Paper sx={{width:{xs:"100%", md:"70%"}, p:2}} style={{display:"flex" , flexDirection:"column", gap:10}}>
        <Typography variant="h6">Services opted </Typography>

         <TableContainer >
         <Table sx={{ minWidth: {xs:400, md:650} }} aria-label="simple table">
           <TableHead>
             <TableRow>
               <TableCell style={{fontWeight:"bold"}}>Service</TableCell>
               <TableCell style={{fontWeight:"bold"}} align="right">Date</TableCell>
               <TableCell style={{fontWeight:"bold"}} align="right">Cost</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
            {ServicesInCart.map(Item=>
                cart[Item].cost ?
               <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                <TableCell>{Item}</TableCell>
                <TableCell align="right">{cart[Item].date}</TableCell>
                <TableCell align="right">{cart[Item].cost}</TableCell>
                <Button variant="contained" onClick={()=>handleRemove(Item)}  style={{borderRadius:0 , color:"#000", backgroundColor:"#efefef"}} >
                  Remove
                </Button>
                 </TableRow> : (cart[Item].requirements ?
                 <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{Item}</TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">requested quote</TableCell>
                <Button variant="contained" onClick={()=>handleRemove(Item)}  style={{borderRadius:0 , color:"#000", backgroundColor:"#efefef"}} >
                  Remove
                </Button>
                </TableRow>:
                <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={3}>{Item}</TableCell>
                <Button variant="contained" onClick={()=>handleRemove(Item)}  style={{borderRadius:0 , color:"#000", backgroundColor:"#efefef"}} >
                  Remove
                </Button>
                </TableRow>
                {Object.keys(cart[Item]).map(k=>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{k}</TableCell>
                    <TableCell align="right">{cart[Item][k].date}</TableCell>
                    <TableCell align="right">{cart[Item][k].cost}</TableCell>
                </TableRow>
                )}
                </>
              ))}
           </TableBody>
         </Table>
       </TableContainer>
        <Button variant="contained" onClick={handleCheckout}  style={{borderRadius:0 , color:"#fff", backgroundColor:"#000", width:"30%"}} sx={{  mt:3}} >
              Submit
            </Button>
        </Paper>
      }


      <Snackbar
        anchorOrigin={{vertical , horizontal}}
        open={openLoginMessage}
        onClose={handleClose}
        autoHideDuration={6000}
        key={"successful message"}
      >
        <Alert onClose={handleClose} severity={notificationType} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
        </Snackbar>


    </Box>
  );
}

export default Checkout;
