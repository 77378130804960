import React , {useState, useEffect, useContext} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {CartProvider} from "./CartContext";
import "./App.css";
import NavBar from "./Components/NavBar";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard"
import Accreditation from "./Pages/Accreditation";
import Submissions from "./Pages/Submissions";
import Services from "./Pages/Services";
import Checkout from "./Pages/Services/Checkout";
import Registration from "./Pages/Registration";
import NotFound from "./Pages/NotFound";
import PasswordReset from "./Pages/PasswordReset";
import UserContext from "./UserContext";

function App() {
  // const [user, setUser] = useState("");
  // useEffect(()=>{
  //   setUser(JSON.parse(localStorage.getItem("profile")));
  // },[])
  const {user} = useContext(UserContext);
  const admin = process.env.REACT_APP_ADMIN.split(",");
  return (
    <div className="App">
      
      <Router>
      <NavBar />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route index element={user ? <Home /> : <Login />} />
          <Route path="/" element={user ? <Home /> : <Login />} />
          <Route path="dashboard" element={user ? (admin.includes(user.email) ? <Dashboard /> : <Home />) : <Login />} />
          <Route path="home" element={user ? <Home /> : <Login />} />
          <Route path="accreditation" element={user ? <Accreditation /> : <Login />} />
          <Route path="submissions" element={user ? <Submissions /> : <Login />} />
          <Route path="services" element={user ? <Services /> : <Login />} />
            <Route path="checkout" element={<Checkout /> } />
          <Route path="registration" element={<Registration />} />
          <Route path="forgot-password/:email/:token" element={<PasswordReset />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
