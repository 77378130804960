import React, {useState, useEffect, useRef} from "react";
import { Box, TextField, Typography, Button , FormControl, Snackbar,Alert, InputLabel , Select, MenuItem} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {storage} from "../../firebaseapp.js";
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { ref, getDownloadURL, uploadBytesResumable  } from "firebase/storage";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {brandList} from "../../vendorPack.js";

function Registration() {
  const navigate = useNavigate();
  const [selectedFile , setSelectedFile] = useState([]);
  const [userDetails , setUserDetails] = useState({});
  const [error, setError] = useState({})
  const [uploading, setUploading] = useState({})
  const [loading, setLoading] = useState(false)
  const [openLoginMessage, setopenLoginMessage] = useState(true)
  const [notificationMessage, setnotificationMessage] = useState("")
  const [notificationType, setnotificationType] = useState("")
 
  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const validMobile = new RegExp('^\\d{10}$');
  

  const {vertical , horizontal} = {vertical:"bottom", horizontal:"right"};
  const handleClose = () => {
    setopenLoginMessage(false);
  }
  
  const hiddenFileInput = useRef(null);

  const handleUpload = async({name , data}) => {
      // setSelectedFile(e.target?.files[0]);
      console.log(name);
      const FileRef = ref(storage, `${userDetails?.company}/${userDetails?.firstname}/${name}/${name}_${new Date().toISOString().replaceAll(/[.:]/g,"_")}`);
      const uploadTask = uploadBytesResumable(FileRef, data);
      uploadTask.on('state_changed', 
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          setUploading({...uploading , [name] :"ongoing"});
          break;
      }
    }, 
    (error) => {
      console.log(error);
      setnotificationType("error");
      setnotificationMessage("UPload failed!!");
      setopenLoginMessage(true);
      return;
      // Handle unsuccessful uploads
    }, 
    () => {
      // Handle successful uploads on complete
      // // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      // getDownloadURL(FileRef).then(url=>setUploadedURL([...UploadedURL , {title: name , path:url}]));
      setUploading({...uploading , [name] :"done"});
      setnotificationType("success");
      setnotificationMessage("File uploaded successfully");
      setopenLoginMessage(true);
      return;
      });
    };       



  const handleFileSelect = async(e) =>{
      handleUpload({name : e.target.name , data : e.target.files[0]});
  } 

  const handleUserUpdate = (e) =>{
    const temp = userDetails;
    temp[e.target.name] = e.target.value;
    setUserDetails(temp);
  }


  const handleSubmit = async() => {
    if(!userDetails?.firstname){
      setError({...error , username:"required"});
      return;
    }
    if(!userDetails?.email){
      setError({...error , email:"required"});
      return;
    }else if(!validEmail.test(userDetails?.email)){
      setError({...error , email:"invalid"});
      return;
    }
    if(!userDetails?.mobile){
      setError({...error , mobile:"required"});
      return;
    }
    if(!userDetails?.industry){
      setError({...error , industry:"required"});
      return;
    }
  
      // axios.post("http://localhost:5081/user", userDetails).then((res)=>{
      //   console.log(res);
      //   if(res.status === 200){
      //     navigate("/home")
      //   }
      // }).catch(error=>console.log(error));
      setLoading(true);
      axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user", userDetails).then((res)=>{
      
        setLoading(false);
        if(res.status === 200){
          setnotificationType("success");
          setnotificationMessage("Registered Successfully!! Please log in to Proceed");
          setopenLoginMessage(true);
        }
      }).catch(error=>{
        setLoading(false);
        if(error.response.status === 404){
          setnotificationType("error");
          setnotificationMessage(error.response.data.message);
          setopenLoginMessage(true);
         
        }});

  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
      }}
      sx={{ m: 6 }}
    >
      <FormControl>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
        sx={{ m: 6 }}
      >
        <Box  style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          width:"100%",
        }}><TextField id="outlined-basic1" fullWidth label="First Name" 
        error={error.username ? true : false}
        helperText = {error.username && error.username}
        name="firstname" required value={userDetails?.firstname} onChange={handleUserUpdate} variant="standard"  />
        <TextField id="outlined-basic2" fullWidth label="Last Name" name="lastname"  value={userDetails?.lastname} onChange={handleUserUpdate} variant="standard"  /></Box>
        <Box  style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          width:"100%",
        }}><TextField id="outlined-basic3" fullWidth label="Email" error={error.email ? true : false}
        helperText = {error.email && error.email} name="email" required value={userDetails?.email} onChange={handleUserUpdate} variant="standard"  />
        <TextField id="outlined-basic4" fullWidth label="Mobile" error={error.mobile ? true : false}
        helperText = {error.mobile && error.mobile} name="mobile" required value={userDetails?.mobile} onChange={handleUserUpdate} variant="standard"  /></Box>
        
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Industry/Role*</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          error={error.industry ? true : false}
          required
          helperText = {error.industry && error.industry}
          value={userDetails?.industry} onChange={handleUserUpdate}
          label="Industry/Role"
          name="industry"
        >
          {["Brand representative","Agency","Production company"].map((ind,key)=><MenuItem value={ind} key={key} >{ind}</MenuItem >)}
        </Select>
      </FormControl>
        {/* <TextField
          id="outlined-basic5"
          label="Company/Brand"
          required
          error={error.company ? true : false}
          helperText = {error.company && error.company}
          variant="standard" 
          name="company" value={userDetails?.company} onChange={handleUserUpdate}
        /> */}
        {/* <TextField id="outlined-basic6" label="Industry" name="industry" value={userDetails?.industry} onChange={handleUserUpdate} variant="standard"  /> */}
        <FormControl  variant="standard">
        <TextField id="outlined-basic7" label="Set Password" name="password" required placeholder
        error={error.password ? true : false}
        helperText = {error.password && error.password} type="password" value={userDetails?.password} onChange={handleUserUpdate} variant="standard"  />
        </FormControl>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width:"100%",
          justifyContent: "center",
          alignItem:'center',
        }}
      >
        {/* <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          
          <Button
                style={{color:"#000", borderColor:"#000", borderRadius:0}}
                variant="outlined"
                aria-label="Trade licence"
                component="label"  
                // disabled = {selectedFile ? true : false}
              >
                Trade License
                <input hidden accept="image/*" ref={hiddenFileInput} onInput={handleFileSelect} name="Trade License" type="file" />
                {uploading["Trade License"] === "done" ? <CheckIcon /> : <FileUploadIcon />}
              </Button>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
                style={{color:"#000", borderColor:"#000" , borderRadius:0}}
                variant="outlined"
                aria-label="TRN"
                component="label"
              >
                <Typography variant="body2">TRN</Typography>
                <input hidden accept="image/*" ref={hiddenFileInput} onInput={handleFileSelect} name="TRN"   type="file" />
                {uploading["TRN"] === "done" ? <CheckIcon /> : <FileUploadIcon />}
              </Button>
        </Box> */}
        <LoadingButton
        loading={loading}
        disabled={loading}
          loadingPosition="end"
          variant="contained"
          style={{ color: "gold", backgroundColor: "black", borderRadius:0 , width: "150px"}}
          onClick={handleSubmit}
        >
          Register
        </LoadingButton>
      </Box>
      </FormControl>
      <Snackbar
        anchorOrigin={{vertical , horizontal}} 
        open={openLoginMessage}
        onClose={handleClose}
        autoHideDuration={6000}
        key={"successful message"}
      >
        <Alert onClose={handleClose} severity={notificationType} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
        </Snackbar>
    </Box>
  );
}

export default Registration;
