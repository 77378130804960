export const VendorPack = {
        Anchor:"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F1_ANCHOR_SOLEDXB2022_VENDORPACK_v1.5.pdf?alt=media&token=f70dfdd0-69c1-41f0-868c-1bef5ebd0ffe",
        "Large Custom":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F2_LARGE%20CUSTOM_SOLEDXB2022_VENDORPACK_v1.5.pdf?alt=media&token=cd915434-e6e0-4fb3-bdf0-aae7d2a1b8c4",
        Medium:"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F3_MEDIUM_SOLEDXB2022_VENDORPACK_v1.5.pdf?alt=media&token=7cc5dd60-9603-4c95-946d-513100519ff7",
        Small:"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F4_SMALL_SOLEDXB2022_VENDORPACK_v1.5.pdf?alt=media&token=b0cfbfa9-0805-4bbb-9b1c-1afd604d69f5",
        Sponsor:"",
        "F&B":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F9.%20F%26B_SOLEDXB2022_VENDORPACK_v2.1.pdf?alt=media&token=64fa5573-7b8e-4198-8fd2-b445ef4151a7",
        Sponsor_PH:"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F7_PH_ASOC%20SPONSOR_SOLEDXB2022_VENDORPACK_v1.5.pdf?alt=media&token=6bdcaff6-5cf5-4efc-86a8-c05763b3dce2",
        "Associate Sponsor":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F6_CASHEW_ASOC%20SPONSOR_SOLEDXB2022_VENDORPACK_v1.5.pdf?alt=media&token=e50e59b7-fdf1-40cc-83a3-1af975d003e5",
        "Medium Sponsor":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F8.%20TIER3_SPONSOR_SOLEDXB2022_VENDORPACK_ASOC_v1.5.pdf?alt=media&token=5a660615-437f-4c41-ae90-2ca21407e000",
        "Sponsor_ASOC":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F10.%20ASOC_TIER%201%20SPONSOR_VENDORPACK_v1.5.pdf?alt=media&token=cbc3f52e-e112-4e83-8f47-2b42dd9f4309",
        "Market":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2F11.%20MARKET_VENDORPACK_SOLEDXB2022_v1.5.pdf?alt=media&token=4502486a-004f-4f5d-9104-61076aa95c9c",


}



export const BrandVendorPackMapping = {
Puma:"Anchor",
"New Balance":"Anchor",
"The Giving Movement":"Anchor",
"Jägermeister": "Sponsor_ASOC",
"Patrón": "SponsorTBC",
"Les Benjamins" :"Large Custom", 
Crocs :"Large Custom", 
"Crep Protect":"Large Custom",
"Hugo B oss" :"Large Custom", 
"Fred Perry" :"Large Custom", 
"Kappa":"Large Custom", 
"Sephora":"Large Custom", 
"Adidas Originals":"Large Custom", 
"2K":"Large Custom", 
"Freedom":"Large Custom", 
AAPE :"Medium", 
"D1 Milano" :"Medium", 
"1886" :"Medium", 
"5ive Pillars" :"Medium", 
"Shabab Intl" :"Medium", 
"Virgin Megastore" :"Medium", 
"The Gold Gods" :"Medium", 
"Le Labo" :"Medium", 
"No. 10":"Medium", 
"OTB" :"Medium", 
"Azeema Magazine" :"Medium", 
"On Running" :"Medium", 
"Precious Trust" :"Medium", 
"XL Extralight + RAL7000 Studio" :"Medium", 
"Blockhead" :"Medium", 
"Def Jam" :"Medium", 
"BLESSD" :"Medium", 
"Sole Graduate Showcase":"Medium", 
PPC:"Medium", 
Toms :"Small", 
Claudette :"Small", 
"Wild Arab West" :"Small", 
"Angel Syrup" :"Small", 
"Crkd Guru" :"Small", 
"Freedom of Space" :"Small", 
"Mitchell & Ness" :"Small", 
Dopamine:"Small",
Grade:"Small",
"25Hours Hotel":"Small",
"West to West Kitchen": "F&B",
"Rascals": "F&B",
"Neat Burger": "F&B",
"Cali Poke": "F&B",
"Pickl": "F&B",
"Shake Shack": "F&B",
"Burro Blanco": "F&B",
"Yifang Tea":"F&B",
"Artisan Ice":"F&B",
"Pinza":"F&B",
"O Donuts":"F&B",
"Fifth Flavor":"F&B",
"Project Chaiwalla":"F&B",
"Trill Icecream":"F&B",
"Meera Adnan":"Market",
"SN3":"Market",
"Plussmmmore":"Market",
"Zac Bendriouich":"Market",
"Thaely":"Market",
"Pacifism":"Market",
UNTY:"Market",
"Trigger Malfunction":"Market",
"Kardo":"Market",
"Sound.On":"Market",
"Finchitua":"Market",
"Face Yoga":"Market",
"Power Horse" :"Sponsor_PH", 
Cashew :"Associate Sponsor", 
Philips :"Medium Sponsor", 
};

export const brandList = ["Puma","New Balance","The Giving Movement","Les Benjamins","Crocs","Crep Protect","Hugo Boss","Fred Perry","Kappa","Sephora","Adidas Originals","2K","Freedom","AAPE","D1 Milano","1886","5ive Pillars","Shabab Intl","Virgin Megastore","The Gold Gods","Le Labo","No. 10","OTB","Azeema Magazine","On Running","Precious Trust","XL Extralight + RAL7000 Studio","Blockhead","Def Jam","BLESSD","Toms","Claudette","Wild Arab West","Angel Syrup","Crkd Guru","Freedom of Space","Mitchell & Ness","Power Horse","Cashew","Jägermeister","Patrón","Philips","West to West Kitchen","Rascals","Neat Burger","Cali Poke","Pickl","Shake Shack"]