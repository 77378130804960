import React from "react";
import CartContext from "../CartContext";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReqBlock from "./ReqBlock";
import { v4 as uuidv4 } from 'uuid';

function RateCard({ data}) {
  const { title, type, dList, dates , cost, deliveryCharge} = data;
  const {cart ,setCart } =  React.useContext(CartContext);
  const [req, setReq] = React.useState([{}]);
 
 
  const handleAdd = () => {
    setReq([...req, {}]);
  };

  


  const handleRemove = async(id) =>{
    
    const removed = await req.filter(r=> r.id !== id);
    await setReq([...removed]);
  }

  const handleAddToCart = () => {
      setCart({...cart , [title]:req})
  }

  return (
    <Accordion sx={{width:{xs:"100%",md:"70%"}}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{color:"#000"}} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* {cart?.[title] ? 
        <Typography>{title} {(cart?.[title]?.length) === 1 ?  `: ${cart?.[title]?.length} ${title.toLowerCase()} added to cart` : `: ${cart?.[title]?.length} ${title.toLowerCase()}'s added to cart`}</Typography>
          : <Typography>{title}</Typography>
        } */}
        <Typography>{title}</Typography>
       
        </AccordionSummary>
      <AccordionDetails style={{ position: "relative" , display:"flex", gap: 16, flexDirection: "column"}}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: 15,
            gap:3,
          }}
        >
          <Button variant="outlined" style={{borderRadius:0, color:"#000", borderColor:"#000"}} onClick={handleAdd}>
            Add more
          </Button>
         
        </Box>
        {deliveryCharge && <Typography sx={{ mt: 1, mb: 1 }} variant="body1">
            Delivery / Transport charges : {deliveryCharge}
          </Typography>}
        {req?.map((r, index) => (
          <ReqBlock index={index} id={r?.id} title={title} handleRemove={handleRemove} type={type} dList={dList} dates={dates} cost={cost} req={req} setReq={setReq}/>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default RateCard;
