import React , {useState,useContext} from "react";
import { TextField, Box, Button, InputAdornment , IconButton,FormControl, styled } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import UserContext from "../../UserContext";

const CssTextField = styled(TextField)({
 
  '& label': {
    translate: '50%',
  }
});


function Login() {
  const navigate = useNavigate();
  const {setUser} = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({username:"", password:""});
  const [loading, setLoading] = useState(false);
  const emailVal = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

  const handleClickShowPassword = () => {
    setShowPassword( !showPassword,);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  const handleForgotPassword= () => {
    axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/forgot-password",{email : email}).then((res)=>console.log(res));
  }

  const handleLogin = () =>{
    if(!email){
      setError({...error , username:"Enter Username" });
      return;
    }
      else if(!password ){
        setError({...error , password:"Enter a password" });
        return;
      }
      else{
        if(!emailVal.test(email)){
          setError({...error , username:"Invalid Email" });
          return;
        }
      }
    const data = {email : email , password : password};
    setLoading(true);
    axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/login", data).then((res)=>{
        setLoading(true);
       localStorage.setItem('profile', JSON.stringify({...res?.data})); 
       setUser({...res?.data.user});
       navigate("/home")
      }).catch(error=>{
        setLoading(false);
        if(error?.response?.status === 403){
          setError({...error, password:error?.response?.data?.message})
        }
        console.log(error);});
  }



  return (
    <div style={{ width: "100%" }}>
      <Box
      sx={{padding:{xs:0,md:"10rem 20rem 0 20rem"}}}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 40,
        }}
      > <Box  
        sx={{flexDirection:{xs:"column",sm:"row"}, gap:{xs:5,md:10}}}
        style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign:"center",
      }}>
        
        <CssTextField sx={{m:{xs:4,md:0}}} id="outlined-basic" value={email} 
        error = {error?.username !== "" ? true : false}
        
        helperText={error.username !== "" && error.username}
        inputProps={{min: 0, style: { textAlign: 'center' }}} onChange={(e)=> setEmail(e.target.value) } label="Email Address" variant="standard" />
        <FormControl sx={{ m: 1}} variant="standard">
        <CssTextField id="standard-adornment-password"
        error = {error?.password !== "" ? true : false}
        fullWidth
        helperText={error.password !== "" && error.password}
        value ={password} inputProps={{min: 0, style: { textAlign: 'center' }}} type='password'
        
            onChange={(e)=> setPassword(e.target.value)} label="Password" variant="standard" />
            </FormControl>
        </Box>
        <LoadingButton
          variant="outlined"
          loading={loading}
          disabled={loading}
          loadingPosition="end"
          style={{
            borderColor: "#000",
            backgroundColor:"#bdbdbd",
            borderRadius:0,
            color: "#000",
            width:"300px",
          }}
          onClick={handleLogin}
        >
          Login
        </LoadingButton>
        <Button
        href="/registration"
          variant="outlined"
          style={{
            borderColor: "#000",
            borderRadius:0,
            backgroundColor: "#30302f",
            color: "#fff",
            width:"300px",
          }}
        >
          Register
        </Button>
        <Button onClick={handleForgotPassword}>forgot password</Button>
        
      </Box>
    </div>
  );
}

export default Login;
