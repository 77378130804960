import React from "react";
import CartContext from "../CartContext";
import Request from "../Components/Request";
import {
  Box,
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ListSubheader,
} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';




function ReqBlock({ index, type, id , dList, dates, handleRemove , title, cost, req ,setReq}) {
  const {cart ,setCart } =  React.useContext(CartContext);
  

  return (
    <Box sx={{mt:4 ,display:"flex", flexDirection:"column", gap:5}}>
      {/* <Typography sx={{ mb: 1 }} variant="body2">
        {`Request ${index + 1}`}
      </Typography> */}
     
        {/* <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>Cost : {(cost && duration !== "") ? req.cost : <span style={{color:"red"}}>"Select a duration"</span>}  </Typography>
         
        </Box> */}
        {/* <Button variant="outlined" style={{borderRadius:0, color:"#000", borderColor:"#000"}} onClick={()=>handleRemove(id)}>
            Remove
          </Button> */}
      
       <Box >
         
      <Box style={{display:"flex", flexDirection:"column"}}>
      {dList.map(d=>
      <>
      <Request d={d} cost={cost} type={type} title={title} index={index} dates={dates} req={req} />
      {/* <Box sx={{display:"flex", alignItems:"center" , justifyContent:"space-between"}}><FormControlLabel
      sx={{ mt: 2 }}
      control={<Checkbox  style={{color:"#000"}}  name={d} onChange={handleTypeReqSelect} value={d}/>}
      label={d}
        />
        <Typography>{cost?.[type]?.[d]?.toLocaleString('en-US', {style:"currency", currency: 'AED'}).replace("AED", "")}</Typography>
        </Box>
        <Box sx={{ display:"flex", gap:3}} >
          <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">{d === "1 Day" || d === "Extra Hour" ? "Date" : "Dates"}</InputLabel>
             <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={req.dates}
              disabled={!typeReq?.[d]}
              name={d}
              label={d === "1 Day" || d === "Extra Hour" ? "Date" : "Dates"}
              onChange={handleValChange}
            >
              {dates.map((sel) =>  renderSelectGroup(sel,d)
                )}
              
            </Select>
          </FormControl>
        {d === "Extra Hour" &&
       
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">No of Hours</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name={index}
            label="No of Hours"
            onChange={handleHoursChange}
          >
            {[1,2,3,4,5].map((d) => (
              <MenuItem value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl> }
        </Box> */}
        </>)}
      </Box> 
       </Box>
      
       
    
    </Box>
  );
}

export default ReqBlock;
