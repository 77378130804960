import React,{useEffect, useState} from "react";
import {Box, Paper, Typography, Accordion, AccordionDetails, AccordionSummary, Skeleton, Stack } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";


const Dashboard = () => {
    const [registedUser, setRegisteredUser]=useState(0);
    const [submissions, setSubmissions]=useState(0);

    useEffect(()=>{
        axios.get("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/dashboard").then((response)=>{
            setRegisteredUser(response.data);
        }).catch((error)=>{
            console.log(error);
        })
        axios.get("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/dashboard/submissions").then((response)=>{
            setSubmissions(response.data);
            console.log(response.data);
        }).catch((error)=>{
            console.log(error);
        })
        
    },[])

    return (
        <Box sx={{m:4, display:"flex", flexDirection:"column", gap:10}}>
            <Box sx={{width:{xs:"100%", md:"50%" , display:"flex", gap:50}}}>
                
            <Paper sx={{p:5}}>
            {submissions ? 
                <Typography>No of company requesting services {Object.keys(submissions).length}</Typography>
                :
                <Skeleton variant="text" sx={{ fontSize: '1rem' , width:"4rem" }} />
                }
            </Paper>
           
            
            <Paper sx={{p:5}}>
            {registedUser ? 
                <Typography>No of Registered Users {registedUser}</Typography>
                :
                <Skeleton variant="text" sx={{ fontSize: '1rem' , width:"4rem" }} />
                }
            </Paper>
            </Box>
            <Box>
            <Paper sx={{p:5}}>
                <Typography>Services Requested</Typography>
                
                { submissions ? Object.keys(submissions).map(company=>
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{`${company} -  [${Object.keys(submissions[company]).length}]`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.keys(submissions[company]).map(user=>
                    <Accordion >
                         <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                    <Typography>{`${user} - [${Object.keys(submissions[company][user]).length}]`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {Object.keys(submissions[company][user]).map(servicereq =>
                                    <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                            <Typography>{`${servicereq}`}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                    {Object.keys(submissions[company][user][servicereq]).map(item=>
                                                    <Box sx={{display:"flex", flexDirection:"column"}}>
                                                        {typeof submissions[company][user][servicereq][item] !== "object" ? 
                                                        <Typography>{`${item} : ${submissions[company][user][servicereq][item]}`}</Typography> 
                                                    :
                                                        <>
                                                        <Typography>{item}</Typography>
                                                        {Object.keys(submissions[company][user][servicereq][item]).map(i =>
                                                            <Box sx={{pl:2}}>
                                                                {typeof submissions[company][user][servicereq][item][i] !== "object" ? 
                                                                <Typography>{`${i} : ${submissions[company][user][servicereq][item][i]}`}</Typography>
                                                                :
                                                                <>
                                                                    <Typography>{i}</Typography>
                                                                    {Object.keys(submissions[company][user][servicereq][item][i]).map(k =>
                                                                        <Typography>{`${k} : ${submissions[company][user][servicereq][item][i][k]}`}</Typography>
                                                                    )}
                                                                </>
                                                                }
                                                            </Box>
                                                            
                                                            )}
                                                        </>

                                                    }
                                                        
                                                    </Box>    
                                                    )}
                                            </AccordionDetails>
                                </Accordion>
                        )}
                    </AccordionDetails>
                    </Accordion>
                )}
                </AccordionDetails>
                </Accordion>
                ) : 
                <Skeleton sx={{ height: 20 }} variant="rectangular" />
                }
            </Paper>
            </Box>
        </Box>
    );

}


export default Dashboard