import React, {useContext} from "react";
import UserContext from "../../UserContext";
import {
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Snackbar,Alert,TextField
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ref, getDownloadURL, uploadBytesResumable  } from "firebase/storage";
import {storage} from "../../firebaseapp.js";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>
        {children}
      </Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Submissions() {
  const [value, setValue] = React.useState(0);
  const [design, setDesign] = React.useState({});
  const [technical, setTechnical] = React.useState({});
  const [activation, setActivation] = React.useState({});
  const [financeContact, setFinanceContact] = React.useState("");
  const [financeNumber, setFinanceNumber] = React.useState("");
  const [selectedFile , setSelectedFile] = React.useState([]);
  const [UploadedURL , setUploadedURL] = React.useState([]);
  const [fileDetails , setFileDetails] = React.useState([]);
  const [openLoginMessage, setopenLoginMessage] = React.useState(true)
  const [notificationMessage, setnotificationMessage] = React.useState("")
  const [notificationType, setnotificationType] = React.useState("")
  const [uploading , setUploading] = React.useState({"Design Concept": 'no', "Technical Submission": 'no', "Activation Submission": 'no' , "Trade license":"no", "TRN" : "no"});
  
  const {vertical , horizontal} = {vertical:"bottom", horizontal:"right"};
  const handleClose = () => {
    setopenLoginMessage(false);
  }

 

  const {user} = useContext(UserContext);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDesignChange = (e)=>{
    setDesign({...design , [e.target.name] : e.target.checked});
  }
  const handleTechnicalChange = (e)=>{
    setTechnical({...technical , [e.target.name] : e.target.checked});
  }
  const handleActivationChange = (e)=>{
    setActivation({...activation , [e.target.name] : e.target.checked});
  }

  const handleSubmit = () =>{
    console.log(selectedFile);
    const desginSelections = Object.keys(design).filter(selection => design[selection])
    const technicalSelections = Object.keys(technical).filter(selection => technical[selection])
    const activationSelections = Object.keys(activation).filter(selection => activation[selection])
    const submission =  {
      design:  desginSelections ,
     technical:technicalSelections,
     activation:activationSelections,
     FPC : financeContact,
     FMNB: financeNumber,
    }
    const email = JSON.parse(localStorage.getItem('profile')).user.email;
    axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/submission", {...submission , email:email  }).then((res)=>{
        console.log(res);
        if(res.status === 200){
          setnotificationType("success");
          setnotificationMessage("Submitted");
          setopenLoginMessage(true);
          return;
        }
      }).catch(error=>{
        setnotificationType("error");
        setnotificationMessage("Submission Failed");
        setopenLoginMessage(true);
        return;
    }
      );

  }

  const handleFileSelect = async(e) =>{
    console.log(e.target.files[0].name);
    handleUpload({name: e.target.files[0].name , data : e.target.files[0], uploadingItem : e.target.name} )
  }


  const handleUpload = async({name , data, uploadingItem}) => {
    // setSelectedFile(e.target?.files[0]);
    const fname = name.split(".");
    const FileRef = ref(storage, `${user.company}/${user.firstname}/submissions/${uploadingItem}/${fname[0]}_${new Date().toISOString().replaceAll(/[.:]/g,"_")}.${fname[1]}`);
    const uploadTask = uploadBytesResumable(FileRef, data);
    uploadTask?.on('state_changed', 
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          setUploading({...uploading , [uploadingItem]: "ongoing"});
          break;
      }
    }, 
    (error) => {
      console.log(error);
      setnotificationType("error");
      setnotificationMessage("UPload failed!!");
      setopenLoginMessage(true);
      return;
      // Handle unsuccessful uploads
    }, 
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/upload", {username: user.firstname, company:user.company, url:downloadURL , upload:uploadingItem}).then(res=>{
        if(res.status === 200){
          setUploading({...uploading , [uploadingItem]: "done"});
          setnotificationType("success");
          setnotificationMessage("File uploaded successfully");
          setopenLoginMessage(true);
          return;
        }
      })
      });
      
      
      });
    };  

  return (
    <Box sx={{ padding:{xs:"1rem", md:"2rem 8rem"}}}>
      <div
        className="intro"
        style={{
          display: "flex",
        }}
      >
        <Box className="left" sx={{ width: {xs:"100%", md:"60%"}}}>
          <Typography variant="h6">Stand-out Design</Typography>
          <Typography variant="body1">
            Create the best experience to the mutual benefit of the festival and
            your brand.
          </Typography>
          <Typography variant="body1">
            The Design Submission is a presentation that outlines the general concept and creative direction for the space, as well as the experience it creates for your audience.
          </Typography>
          <Typography variant="body1" sx={{mt:2}} >
            <b>Note:</b>
          </Typography>
          <ul>
            <li>Click the ‘Upload files’ button to add files from your computer.</li>
            <li>Files can be uploaded individually or as a zipped folder.</li>
            <li>Please use the check boxes to select relevant documents being submitted.</li>
          </ul>
          <Typography variant="body1" >
            <b>Deadline:</b>
          </Typography>
          <ul>
            <li>The deadline for submissions is October 17.</li>
          </ul>
        </Box>
        
      </div>
      <Box sx={{ width: {xs:"100%", md:"80%"} }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tooltip
              arrow
              title="A presentation that outlines the general concept and creative direction for the space and experience communicated through mood boards, a brief description, and supporting visuals or sketches. 
Refer Sections 6.b and 7 in the Vendor Pack for more details.
"
              placement="right"
            >
              <Tab
                icon={<InfoIcon style={{ color:"#000" }} />}
                iconPosition="end"
                label="Design Concept"
                {...a11yProps(0)}
              />
            </Tooltip>
            <Tooltip
              arrow
              title="Technical information to support your Design Concept, in compliance with local building rules and regulations of both Dubai Municipality and Civil Defence. 
Refer Sections 6.c and 7 in the Vendor Pack for more details.
"
              placement="right"
            >
              <Tab
                icon={<InfoIcon style={{ color:"#000" }} />}
                iconPosition="end"
                label="Technical Submission"
                {...a11yProps(1)}
              />
            </Tooltip>
            <Tooltip
              arrow
              title="Promotional activities planned at the festival that require pre-approval in writing by SOLE and DTCM permits.
Refer Sections 2.f and 6.a in the Vendor Pack for more details.
"
              placement="right"
            >
              <Tab
                icon={<InfoIcon style={{ color:"#000" }} />}
                iconPosition="end"
                label="Activation & Programming"
                {...a11yProps(2)}
              />
            </Tooltip>
            <Tooltip
              arrow
              title="Company details towards which estimates and POs will be raised for site services
              ordered."
              placement="right"
            >
              <Tab
                icon={<InfoIcon style={{ color:"#000" }} />}
                iconPosition="end"
                label="Billing information"
                {...a11yProps(3)}
              />
            </Tooltip>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={design?.visual}
              onChange={handleDesignChange}
              name="visual"  />}
              
              label="3D visual in colour (Google SketchUp compatible)"
            />
            <FormControlLabel
              control={<Checkbox  checked={design?.plan}
              onChange={handleDesignChange}
              name="plan" />}
              label="Plan - dimensional layout of major unit components"
            />
            <FormControlLabel
              control={<Checkbox checked={design?.elavation}
              onChange={handleDesignChange}
              name="elavation" />}
              label="Elevations – dimensional layout of all unit elements"
            />
            <FormControlLabel
              control={<Checkbox checked={design?.material}
              onChange={handleDesignChange}
              name="material" />}
              label="Material sample board (On SOLE’s request)"
            />
          </FormGroup>
          <LoadingButton
                sx={{width:{xs:"300px", md:"330px"}}}
                style={{color:"#000", borderColor:"#000", borderRadius:0 , marginTop:"2rem"}}
                variant="outlined"
                loading={uploading["Design Concept"] === "ongoing" ? true : false}
                loadingPosition="end"
                disabled={uploading["Design Concept"] === "ongoing" ? true : false}
                aria-label="upload file"
                component="label"
              >
                <Typography variant="body2">Upload Files - Design Concept </Typography>
                <input hidden accept="*" onInput={handleFileSelect} name="Design Concept" type="file" />
                {uploading["Design Concept"] === "done" ? <CheckIcon /> : uploading["Design Concept"] === "ongoing" ? "" : <FileUploadIcon />}
          </LoadingButton>
             
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={technical?.["Plan and layout"]}
              onChange={handleTechnicalChange}
              name="Plan and layout" />}
              label="Plan and layout - GA & power (dimensioned and annotated)"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Elavations}
              onChange={handleTechnicalChange}
              name="Elavations" />}
              label="Elevations (dimensioned and annotated)"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Sections}
              onChange={handleTechnicalChange}
              name="Sections" />}
              label="Sections (dimensioned and annotated)"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Spot}
              onChange={handleTechnicalChange}
              name="Spot" />}
              label="Spot details – junctions, skirting, light boxes, drawers etc."
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.MaterialFinishes}
              onChange={handleTechnicalChange}
              name="MaterialFinishes"/>}
              label="Material finishes specificationsSpot details – junctions, skirting, light boxes, drawers etc."
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Electrical}
              onChange={handleTechnicalChange}
              name="Electrical" />}
              label="Electrical load schedule"
            />
            <FormControlLabel control={<Checkbox checked={technical?.Wiring}
              onChange={handleTechnicalChange}
              name="Wiring" />} label="Wiring diagram" />
            <FormControlLabel
              control={<Checkbox checked={technical?.Smoke}
              onChange={handleTechnicalChange}
              name="Smoke" />}
              label="Smoke alarms and extinguisher plan"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Emergency}
              onChange={handleTechnicalChange}
              name="Emergency" />}
              
              label="Emergency exits and evacuation plan"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Structural}
              onChange={handleTechnicalChange}
              name="Structural" />}
              
              label="Structural and wind load calculations (SAAP & STAAD)"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Capacity}
              onChange={handleTechnicalChange}
              name="Capacity" />}
              
              label="Capacity calculations"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Schedule}
              onChange={handleTechnicalChange}
              name="Schedule" />}
              
              label="Schedule for fabrication, installation and deconstruction"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Contact}
              onChange={handleTechnicalChange}
              name="Contact" />}
              
              label="Contact details - agency and production fabricators/installers"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Fabricator}
              onChange={handleTechnicalChange}
              name="Fabricator" />}
              
              label="Fabricator/contractor - trade licence and appointment letters"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Method}
              onChange={handleTechnicalChange}
              name="Method" />}
              
              label="Method statement for installation and tear down"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Liabiliy}
              onChange={handleTechnicalChange}
              name="Liabiliy" />}
              
              label="3rd party liability insurance"
            />
            <FormControlLabel
              control={<Checkbox checked={technical?.Sustainability}
              onChange={handleTechnicalChange}
              name="Sustainability" />}
              
              label="Sustainability plan"
            />
          </FormGroup>
          <LoadingButton
                sx={{width:{xs:"280px", md:"400px"}}}
                style={{color:"#000", borderColor:"#000", borderRadius:0 , marginTop:"2rem"}}
                variant="outlined"
                loading={uploading["Technical Submission"] === "ongoing" ? true : false}
                disabled={uploading["Technical Submission"] === "ongoing" ? true : false}
                loadingPosition="end"
                aria-label="upload file"
                component="label"
              >
                <Typography variant="body2">Upload Files - Technical Submission </Typography>
                <input hidden accept="*" onInput={handleFileSelect} name="Technical Submission" type="file" />
                {uploading["Technical Submission"] === "done" ? <CheckIcon /> : uploading["Technical Submission"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton> 
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={activation?.Key}
              onChange={handleActivationChange}
              name="Key" />}
              
              label="Key product drops/releases"
            />
            <FormControlLabel
              control={<Checkbox checked={activation?.Activation}
              onChange={handleActivationChange}
              name="Activation" />}
              
              label="Activation format"
            />
            <FormControlLabel
              control={<Checkbox checked={activation?.Potential}
              onChange={handleActivationChange}
              name="Potential"  />}
              
              label="Any other potential programming"
            />
            <FormControlLabel
              control={<Checkbox checked={activation?.Exclusive}
              onChange={handleActivationChange}
              name="Exclusive" />}
              
              label="Exclusive collections (if any)"
            />
            <FormControlLabel
              control={<Checkbox checked={activation?.Special}
              onChange={handleActivationChange}
              name="Special" />}
              
              label="Special offers/competitions"
            />
            <FormControlLabel
              control={<Checkbox checked={activation?.Entertainer}
              onChange={handleActivationChange}
              name="Entertainer" />}
              
              label="Entertainer DTCM documentation (if applicable)"
            />
          </FormGroup>
          <LoadingButton
                sx={{width:{xs:"300px", md:"390px"}}}
                style={{color:"#000", borderColor:"#000", borderRadius:0 , marginTop:"2rem"}}
                variant="outlined"
                loading={uploading["Activation Submission"] === "ongoing" ? true : false}
                loadingPosition="end"
                disabled={uploading["Activation Submission"] === "ongoing" ? true : false}
                aria-label="upload file"
                component="label"
              >
                <Typography variant="body2">Upload Files - Activation Submission </Typography>
                <input hidden accept="*" onInput={handleFileSelect} name="Activation Submission" type="file" />
                {uploading["Activation Submission"] === "done" ? <CheckIcon /> : uploading["Activation Submission"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton> 
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Box sx={{display:"flex", flexDirection:"Column", gap:1}}>
        <TextField id="standard-basic" required label="Finance Point of Contact" sx={{width:{xs:"250px",md:"400px"}}} value={financeContact} onChange={(e)=>setFinanceContact(e.target.value)} variant="standard" />
        <TextField id="standard-basic" required label="Finance Mobile Number" sx={{width:{xs:"250px",md:"400px"}}}  value={financeNumber} onChange={(e)=>setFinanceNumber(e.target.value)} variant="standard" />
        <LoadingButton
                sx={{width:{xs:"300px",md:"400px"}}}
                style={{color:"#000", borderColor:"#000", borderRadius:0 , marginTop:"2rem"}}
                variant="outlined"
                loading={uploading["Trade license"] === "ongoing" ? true : false}
                disabled={uploading["Trade license"] === "ongoing" ? true : false}
                loadingPosition="end"
                aria-label="upload file"
                component="label"
              >
                <Typography variant="body2">Upload File - Trade license </Typography>
                <input hidden accept="*" onInput={handleFileSelect} name="Trade license" type="file" />
                {uploading["Trade license"] === "done" ? <CheckIcon /> : uploading["Trade license"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton> 
              <LoadingButton
              sx={{width:{xs:"300px",md:"400px"}}}
                style={{color:"#000", borderColor:"#000", borderRadius:0 , marginTop:"2rem"}}
                variant="outlined"
                loading={uploading["TRN"] === "ongoing" ? true : false}
                disabled={uploading["TRN"] === "ongoing" ? true : false}
                loadingPosition="end"
                aria-label="upload file"
                component="label"
              >
                <Typography variant="body2">Upload File - TRN </Typography>
                <input hidden accept="*" onInput={handleFileSelect} name="TRN" type="file" />
                {uploading["TRN"] === "done" ? <CheckIcon /> : uploading["TRN"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton> 
        </Box>
        </TabPanel>
       
          <Button variant="contained" onClick={handleSubmit}  style={{borderRadius:0 , color:"#fff", backgroundColor:"#000"}} sx={{  mt:3}} >
            submit
          </Button>
      </Box>
      <Snackbar
        anchorOrigin={{vertical , horizontal}} 
        open={openLoginMessage}
        onClose={handleClose}
        autoHideDuration={6000}
        key={"successful message"}
      >
        <Alert onClose={handleClose} severity={notificationType} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
        </Snackbar>
    </Box>
  );
}

export default Submissions;
