// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwqc6zpl8dJzr0EORL2asKGS88G9EGO9g",
  authDomain: "sole-dxb-2022.firebaseapp.com",
  projectId: "sole-dxb-2022",
  storageBucket: "sole-dxb-2022.appspot.com",
  messagingSenderId: "982039094841",
  appId: "1:982039094841:web:0102609b5e5a69d4464fe8",
  measurementId: "G-CPTLZZD04K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export {app , analytics , storage} ;