export const Loadservices = [
    {
      title: "Crane 20 Tonne (10 hours)including operator & fuel",
      type:20,
      cost:{
        20:{"1 Day":2000.00, "3 Days":5400.00, "Weekly":11900.00, "Extra Hour":200.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Loadin",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
            ],
            Weekly: ["29 Nov - 6 Dec"],
            "Extra Hour": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
            ],
          },
        },
        {
          title: "Loadout",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
          },
        },
      ],
    },
    {
      title: "Crane 50 Tonne (10 hours) including operator & fuel",
      type:50,
      cost:{
        50:{"1 Day":3000.00, "3 Days":8100.00, "Weekly":17850.00, "Extra Hour":300.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Loadin",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 1 Dec",
              "30 Nov - 2 Dec",
              "1 Dec - 3 Dec",
              "2 Dec - 4 Dec",
              "3 Dec - 5 Dec",
              "4 Dec - 6 Dec",
            ],
            Weekly: ["29 Nov - 6 Dec"],
            "Extra Hour": [
              "29-Nov-22",
              "30-Nov-22",
              "01-Dec-22",
              "02-Dec-22",
              "03-Dec-22",
              "04-Dec-22",
              "05-Dec-22",
              "06-Dec-22",
            ],
          },
        },
        {
          title: "Loadout",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
          },
        },
      ],
    },
    {
      title: "Forklift 5 Tonne (10 hours) including operator & fuel",
      deliveryCharge:1000.00,
      type:5,
      cost:{
        5:{"1 Day":1200.00, "3 Days":3240, "Weekly":7140.00, "Extra Hour":120.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-22",
              "30-Nov-22",
              "01-Dec-22",
              "02-Dec-22",
              "03-Dec-22",
              "04-Dec-22",
              "05-Dec-22",
              "06-Dec-22",
              "07-Dec-22",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
              "05 Dec - 07 Dec",
            ],
            Weekly: ["29 Nov - 06 Dec", "30 Nov - 07 Dec"],
            "Extra Hour": [
              "29-Nov-22",
              "30-Nov-22",
              "01-Dec-22",
              "02-Dec-22",
              "03-Dec-22",
              "04-Dec-22",
              "05-Dec-22",
              "06-Dec-22",
              "07-Dec-22",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
            "3 Days": ["08 Dec - 10 Dec"],
            "Extra Hour": ["08-Dec-22", "09-Dec-22", "10-Dec-22"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
            Weekly: ["11 Dec - 17 Dec"],
            "Extra Hour": [
              "13-Dec-22",
              "14-Dec-22",
              "15-Dec-22",
              "16-Dec-22",
              "17-Dec-22",
            ],
          },
        },
      ],
    },
    {
      title: "Forklift 7 Tonne (10 hours) including operator & fuel",
      deliveryCharge:1000.00,
      type : 7,
      cost:{
        7:{"1 Day":1500.00, "3 Days":4050.00, "Weekly":8925.00, "Extra Hour":150.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
              "05 Dec - 07 Dec",
            ],
            Weekly: ["29 Nov - 06 Dec", "30 Nov - 07 Dec"],
            "Extra Hour": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
            "3 Days": ["08 Dec - 10 Dec"],
            "Extra Hour": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
          },
        },
      ],
    },{
      title: "Telehandler/Boom Loader 14 Metres",
      deliveryCharge:1000.00,
      type: 14,
      cost:{
        14:{"1 Day":1250.00, "3 Days":3375.00, "Weekly":7437.00, "Extra Hour":125.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-22",
              "30-Nov-22",
              "01-Dec-22",
              "02-Dec-22",
              "03-Dec-22",
              "04-Dec-22",
              "05-Dec-22",
              "06-Dec-22",
              "07-Dec-22",
            ],
            "3 Days": [
              "29 Nov - 1 Dec",
              "30 Nov - 2 Dec",
              "1 Dec - 3 Dec",
              "2 Dec - 4 Dec",
              "3 Dec - 5 Dec",
              "4 Dec - 6 Dec",
              "5 Dec - 7 Dec",
            ],
            Weekly: ["29 Nov - 6 Dec"],
            "Extra Hour": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-22", "09-Dec-22", "10-Dec-22"],
            "3 Days": ["08 Dec - 10 Dec"],
            "Extra Hour": ["08-Dec-22", "09-Dec-22", "10-Dec-22"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
            "Extra Hour": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
          },
        },
      ],
    },
    {
      title: "Telehandler/Boom Loader 17m (10 hours) including operator & fuel",
      deliveryCharge:150,
      type: 17,
      cost:{
        17:{"1 Day":1500.00, "3 Days":4050.00, "Weekly":8925.00, "Extra Hour":150.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
              "05 Dec - 07 Dec",
              "06 Dec - 08 Dec",
            ],
            Weekly: ["29 Nov - 06 Dec, 30 Nov - 07 Dec"],
            "Extra Hour": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
              "08-Dec-23",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-22", "09-Dec-22", "10-Dec-22"],
            "3 Days": ["08 Dec - 10 Dec"],
            "Extra Hour": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
            "Extra Hour": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
          },
        },
      ],
    },
    {
      title: "Cherry Picker 15m (10 hours)",
      deliveryCharge:1000.00,
      type: 15,
      cost:{
        15:{"1 Day":1250, "3 Days":3187.00, "Weekly":6562}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
              "05 Dec - 07 Dec",
            ],
            Weekly: ["29 Nov - 6 Dec"],
            "Extra Hour": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
            "3 Days": ["08 Dec - 18 Dec"],
            "Extra Hour": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
            "Extra Hour": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
          },
        },
      ],
    },{
      title: "Scissor Lift 12m (10 hours)",
      deliveryCharge:1000.00,
      type: 12,
      cost:{
      12:{"1 Day":1000.00, "3 Days":2550.00, "Weekly":5250.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
              "05 Dec - 07 Dec",
            ],
            Weekly: ["29 Nov - 06 Dec", "30 Nov - 07 Dec"],
            "Extra Hour": [
              "29-Nov-22",
              "30-Nov-22",
              "01-Dec-22",
              "02-Dec-22",
              "03-Dec-22",
              "04-Dec-22",
              "05-Dec-22",
              "06-Dec-22",
              "07-Dec-22",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
            "3 Days": ["08 Dec - 10 Dec"],
            "Extra Hour": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
            "Extra Hour": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
          },
        },
      ],
    },{
      title: "Cherry Picker/Scissor Lift | Operator (10 hours)",
      type:1,
      cost:{1:{"1 Day":1500.00, "3 Days":4050.00, "Weekly":8925.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Load in",
          val: {
            "1 Day": [
              "29-Nov-23",
              "30-Nov-23",
              "01-Dec-23",
              "02-Dec-23",
              "03-Dec-23",
              "04-Dec-23",
              "05-Dec-23",
              "06-Dec-23",
              "07-Dec-23",
            ],
            "3 Days": [
              "29 Nov - 01 Dec",
              "30 Nov - 02 Dec",
              "01 Dec - 03 Dec",
              "02 Dec - 04 Dec",
              "03 Dec - 05 Dec",
              "04 Dec - 06 Dec",
              "05 Dec - 07 Dec",
            ],
            Weekly: ["29 Nov - 06 Dec"],
            "Extra Hour": [
              "29-Nov-22",
              "30-Nov-22",
              "01-Dec-22",
              "02-Dec-22",
              "03-Dec-22",
              "04-Dec-22",
              "05-Dec-22",
              "06-Dec-22",
              "07-Dec-22",
            ],
          },
        },
        {
          title: "Event Days",
          val: {
            "1 Day": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
            "3 Days": ["08 Dec - 10 Dec"],
            Weekly: ["08 Dec - 14 Dec"],
            "Extra Hour": ["08-Dec-23", "09-Dec-23", "10-Dec-23"],
          },
        },
        {
          title: "Load out",
          val: {
            "1 Day": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
            "3 Days": [
              "11 Dec - 13 Dec",
            ],
            "Extra Hour": [
              "11-Dec-23",
              "12-Dec-23",
              "13-Dec-23",
            ],
          },
        },
      ],
    },
  ];

  export const Securityservices = [
    {
      title: "Male Security Guard | Day Shift | 1 Day*",
      type: 1,
      cost:1100.00,
      dates: [
        "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"
      ],
    },
    {
      title: "Male Security Guard | Day Shift | 3 Days*",
      type: 3,
      cost:2970.00,
      dates: ["07 Dec - 09 Dec","08 Dec - 10 Dec","09 Dec - 11 Dec"],
    },
    {
      title: "Male Security Guard | Day Shift | 1 Week*",
      type: 7,
      cost:6545.00,
      dates: [
        "07 Dec - 13 Dec"
      ],
    },
    {
        title: "Male Security Guard | Night Shift | 1 Day*",
        type: 1,
        cost:1200.00,
        dates: [
            "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"
          ],
      },
      {
        title: "Male Security Guard | Night Shift | 3 Days*",
         type: 1,
        cost:3240.00,
        dates:["07 Dec - 09 Dec","08 Dec - 10 Dec","09 Dec - 11 Dec"],
      },
      {
        title: "Male Security Guard | Day Shift | 1 Week*",
        type: 7,
        cost:7140.00,
        dates: [
          "07 Dec - 13 Dec"
          ],
      },
      {
        title: "Female Security Guard | Day Shift | 1 Day*",
        type: 1,
        cost:1350.00,
        dates: [
            "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"
          ],
      },
      {
        title: "Female Security Guard | Day Shift | 3 Days*",
        type: 3,
        cost:3645.00,
        dates: ["07 Dec - 09 Dec","08 Dec - 10 Dec","09 Dec - 11 Dec"],
      },
      {
        title: "Male Security Guard | Day Shift | 1 Week*",
        type: 7,
        cost:8032.00,
        dates: [
          "07 Dec - 13 Dec"
          ],
      },
      {
        title: "Male Bouncer | 1 Day*",
        type: 1,
        cost:1500.00,
        dates: [
            "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"],
      },
      {
        title: "Male Bouncer | 3 Days*",
        type: 3,
        cost:4050.00,
        dates: ["07 Dec - 09 Dec","08 Dec - 10 Dec","09 Dec - 11 Dec"],
      },
      {
        title: "Male Bouncer | 1 Week*",
        type: 7,
        cost:8925.00,
        dates: ["07 Dec - 13 Dec"],
      },
      {
        title: "Supervisor | 1 Day*",
        type: 1,
        cost:1500.00,
        dates: [
            "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"
          ],
      },
      {
        title: "Supervisor | 3 Day*",
        type:  3,
        cost:4050.00,
        dates: ["07 Dec - 09 Dec","08 Dec - 10 Dec","09 Dec - 11 Dec"]
      },
      {
        title: "Supervisor | 1 Week*",
        type: 7,
        cost:8925.00,
        dates: ["07 Dec - 13 Dec"],
      },
      {
        title: "CCTV Installation - 8 camera set up | 3 Days*",
        type: 3,
        cost:10000.00,
        dates: ["08 Dec - 10 Dec"]
      },

  ];

  export const Cleaningservices = [
    {
      title: "Male Cleaning Staff | 1 Day*",
      type: 1,
      cost:800.00,
      dates: [
        "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"
      ],
    },
    {
      title: "Male Cleaning Staff | 3 Days*",
      type: 3,
      cost:2160.00,
      dates:["08 Dec - 10 Dec"],
    },{
    title: "Female Cleaning Staff | 1 Day*",
     type: 1,
    cost:800.00,
    dates: [
      "07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23"
    ],
  },
  {
    title: "Female Cleaning Staff | 3 Days*",
    type: 3,
    cost:2160.00,
    dates: ["08 Dec - 10 Dec"],
   }
];

  export const Fireservices = [
    {
      title: "Smoke Alarm System | 3 DAYS",
      addText: "Set of 4, battery operated",
      types: 1,
      cost: 750.00,
      dates: [
        "08 Dec - 10 Dec"
      ]
    },
    {
      title: "Small Fire Extinguisher (set of 2) | 1 Day",
      types: 1,
      cost: 125.00,
      dates: [
        "06-Dec-23","07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23","12-Dec-23"
      ]
    },
    {
      title: "Small Fire Extinguisher (set of 2) | 3 Days",
      type:3,
      cost: 337.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
    title: "Big Fire Extinguisher | 1 Day",
    type: 1,
    cost:250.00,
    dates: [
      "06-Dec-23","07-Dec-23","08-Dec-23","09-Dec-23","10-Dec-23","11-Dec-23","12-Dec-23"
    ]
    },
    {
      title: "Big Fire Extinguisher | 3 Days",
      type:3,
      cost:675.00,
      dates: ["08 Dec - 10 Dec"]
    }
];

export const Storageservices = [
    {
      title: "Container with Light & Padlock [6x2.5m] | 3 Days",
     type:3,
      cost:3500.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
    title: "Container with AC, Light & Padlock [6x2.5m] | 3 Days",
    type: 3,
    cost:6500.00,
    dates: ["08 Dec - 10 Dec"]
  }
];

export const Roomservices = [
    {
      title: "32-foot twin room with attached washroom | 3 Days",
      type: 3,
      cost:9000.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
      title: "32-foot twin room without washroom | 3 Days",
      type: 1,
      cost:7500.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
      title: "20-foot single/twin room without washroom | 3 Days",
      type: 1,
      cost:6500.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
    title: "3-seater Sofa - White | 1 Day",
    type:1,
    cost:500.00,
    dates: ["08-Dec-23","09-Dec-23","10-Dec-23"],
  },
  {
    title: "3-seater Sofa - White | 3 Days",
    type:  3,
    cost:1350.00,
    dates: ["08 Dec - 10 Dec"]
  },
  {
    title: "1-seater Sofa - White | 1 Day",
    type:1,
    cost:300.00,
    dates: ["08-Dec-23","09-Dec-23","10-Dec-23"],
  },
  {
    title: "1-seater Sofa - White | 3 Days",
    type:  3,
    cost:810.00,
    dates: ["08 Dec - 10 Dec"]
  },
  {
    title: "Coffee Table | 1 Day",
    type: 1,
    cost:300.00,
    dates: ["08-Dec-23","09-Dec-23","10-Dec-23"]
  },
  {
    title: "Coffee Table | 3 days",
    types: 3,
    cost:810.00,
    dates:["08 Dec - 10 Dec"]
  },{
    title: "Chairs | 1 Day",
    type: 1,
    cost:150.00,
    dates: ["08-Dec-23","09-Dec-23","10-Dec-23"],
  },{
    title: "Chairs | 3 Days",
    type: 3,
    cost:405.00,
    dates:["08 Dec - 10 Dec"]
  },
  {
    title: "Tables | 1 Day",
    type : 1,
    cost:200.00,
    dates: [
      "08-Dec-23","09-Dec-23","10-Dec-23"
    ],
  },
  {
    title: "Tables | 3 Days",
    type: 3,
    cost:540.00,
    dates: ["08 Dec - 10 Dec"]
  },
  {
    title: "Hanger Racks | 1 Day",
    type: 1,
    cost:500.00,
    dates: [
      "08-Dec-23","09-Dec-23","10-Dec-23"
    ]
  },
  {
    title: "Hanger Racks | 3 Days",
    type:  3,
    cost:1350.00,
    dates: ["08 Dec - 10 Dec"]
  },
  {
    title: "Dustbin | 1 Day",
    type: 1,
    cost:50.00,
    dates: ["08-Dec-23","09-Dec-23","10-Dec-23"],
  },
  {
    title: "Dustbin | 3 Days",
    type: 3,
    cost:100.00,
    dates: ["08 Dec - 10 Dec"]
  },
];


export const Powerservices = [
    {
      title: "Additional 32 Amp 1-phase connection | 3 Days",
      types: 3,
      cost:3000.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
        title: "Additional 32 Amp 3-phase connection | 3 Days",
        type: 3,
        cost:6000.00,
        dates: ["08 Dec - 10 Dec"]
      },
      {
          title: "VT1 Tower (includes fuel) | 3 Day",
          type:  3,
          cost:2500.00,
          dates: ["29 Nov - 01 Dec","30 Nov - 02 Dec","01 Dec - 03 Dec", "02 Dec - 04 Dec","03 Dec - 05 Dec", "04 Dec - 06 Dec"]
        },
        {
            title: "VT1 Tower (includes fuel) | 1 Week",
            type:  7,
            cost:4500.00,
            dates: ["29 Nov - 05 Dec","30 Nov - 06 Dec"]
          },
];

export const Otherservices = [
    {
      title: "Queue Manager (per piece) | 1 Day",
      type:1,
      cost:150.00,
      dates: [
        "08-Dec-23","09-Dec-23","10-Dec-23"
      ]
    },
    {
      title: "Queue Manager (per piece)| 3 Days",
      type:  3 ,
      cost:337.00,
      dates: ["08 Dec - 10 Dec"]
    },
    {
        title: "Ropes & Poles (per piece)| 1 Day",
        type: 1,
        cost:150.00,
        dates:[
          "08-Dec-23","09-Dec-23","10-Dec-23"
        ]
      },
    {
        title: "Ropes & Poles (per piece)| 3 Days",
        types:  3,
        cost:337.00,
        dates: ["08-Dec-23","09-Dec-23","10-Dec-23"]
      },
];
