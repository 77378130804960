import React, {useEffect, useState , useContext} from "react";
import { Box, Typography, IconButton , Button , Snackbar,Alert} from "@mui/material";
import {storage} from "../../firebaseapp.js"
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadIcon from "@mui/icons-material/Download";
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { ref, getDownloadURL, uploadBytesResumable  } from "firebase/storage";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import userEvent from "@testing-library/user-event";
import UserContext from "../../UserContext";

function Accreditation() {

  const navigate = useNavigate();
  const [selectedFile , setSelectedFile] = useState([]);
  const [UploadedURL , setUploadedURL] = useState([]);
  const [fileDetails , setFileDetails] = useState([]);
  const [uploading , setUploading] = useState({"Build and Teardown": "no" , "Event days": "no", "Staff ID":"no" });
  const [openLoginMessage, setopenLoginMessage] = useState(true)
  const [notificationMessage, setnotificationMessage] = useState("")
  const [notificationType, setnotificationType] = useState("")

  const {user} = useContext(UserContext);

  const {vertical , horizontal} = {vertical:"bottom", horizontal:"right"};
  const handleClose = () => {
    setopenLoginMessage(false);
  }


useEffect(()=>{
  console.log(uploading);
},[uploading])

  const handleFileSelect = async(e) =>{
    handleUpload({name: e.target.files[0].name , data : e.target.files[0] , uploadingItem : e.target.name });
  
}

const handleUpload = async({name , data, uploadingItem}) => {
  // setSelectedFile(e.target?.files[0]);
  const fname = name.split(".");
  const FileRef = ref(storage, `${user.company}/${user.firstname}/accreditation/${uploadingItem}/${fname[0]}_${new Date().toISOString().replaceAll(/[.:]/g,"_")}.${fname[1]}`);
  const uploadTask = uploadBytesResumable(FileRef, data);
  uploadTask.on('state_changed', 
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          setUploading({...uploading , [uploadingItem]: "ongoing"});
          break;
      }
    }, 
    (error) => {
      console.log(error);
      setnotificationType("error");
      setnotificationMessage("UPload failed!!");
      setopenLoginMessage(true);
      return;
      // Handle unsuccessful uploads
    }, 
    () => {
      // Handle successful uploads on complete
      // // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      // getDownloadURL(FileRef).then(url=>setUploadedURL([...UploadedURL , {title: name , path:url}]));
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/upload", {username: user.firstname, company:user.company , url:downloadURL , upload:uploadingItem}).then(res=>{
        if(res.status === 200){
          setUploading({...uploading , [uploadingItem]: "done"});
          setnotificationType("success");
          setnotificationMessage("File uploaded successfully");
          setopenLoginMessage(true);
          return;
        }
      })
      });
      
      });
    };    


// const handleSubmit = ()=> {
//   axios.post("https://us-central1-sole-dxb-2022.cloudfunctions.net/app/user/accreditation", {accreditation : fileDetails , email : user.email}).then((res)=>{
//         console.log(res);
//         if(res.status === 200){
//           navigate("/home")
//         }
//       }).catch(error=>console.log(error.message));

// }

  return (
    <Box>
      <Box sx={{ padding:{xs:"1rem", md:"2rem 8rem"  } }}>
        <Box>
          <Typography variant="h6">Sign Up</Typography>
          <Typography variant="body1">
          All on-site personnel must be credentialed. Please use the templates below to provide names, ID copies, and job titles for credentials to be issued.
          </Typography>
          <Typography variant="body1" sx={{mt:2}}><b>Note:</b></Typography>
          <ul>
            <li>Click ‘Download template’</li>
            <li>Please fill in personnel details for all fields listed in the downloaded template.</li>
            <li>Use the ‘Upload file’ button to submit the filled-in template.</li>
          </ul>
          <Typography variant="body1"><b>Deadline:</b></Typography>
          <ul>
            <li>The deadline for accreditation is November 14.</li>
          </ul>
        </Box>
        <Box
          sx={{ mt: 6, gap: {xs:2, md:5  } }}
          style={{ display: "flex", flexDirection: "column"}}
        >
          <Box
            className="event days"
            sx={{
              width: {xs:"90%", md:"50%"},
              border: "1px dashed #000",
              margin: 3,
              padding: {xs:3, md: 3},
              display:"flex",
              gap:1,
              flexDirection: "column",
            }}
          >
            <Typography variant="body1">Build & Teardown Accreditation</Typography>
            <Typography variant="body1">Use this template for access to the site on build and teardown days only. Validity of these site badges will expire on December 08 at 12:00.</Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <a href="https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FAccreditation%2FSOLEDXB%202022_BUILD%20TEARDOWN%20Accredition%20Form.xlsx?alt=media&token=393af994-c4cf-4b0f-a623-ca1b793a1032" download target="_blank" style={{textDecoration:"none"}} rel="opener">
               <Button
                style={{color:"#000", borderColor:"#000" , borderRadius:0}}
                variant="outlined"
                aria-label="Download Template"
                component="label"
              >
                <Typography variant="body2">Download Template</Typography>
                <DownloadIcon />
              </Button>
              </a>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              
              <LoadingButton
                style={{color:"#000", borderColor:"#000", borderRadius:0, width:"330px"}}
                variant="outlined"
                aria-label="upload file - Buid and Teardown"
                component="label"
                loading={uploading["Build and Teardown"] === "ongoing" ? true : false}
                loadingPosition="end"
                disabled={uploading["Build and Teardown"] === "ongoing" ? true : false}
              >
                <Typography variant="body2">upload file - Build and Teardown</Typography>
                <input hidden accept=".xls, .xlsx" onInput={handleFileSelect} name="Build and Teardown" type="file" />
                {uploading["Build and Teardown"] === "done" ? <CheckIcon /> : uploading["Build and Teardown"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton>
              
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              
              <LoadingButton
                style={{color:"#000", borderColor:"#000", borderRadius:0, width:"330px", justifyContent:"flex-start"}}
                variant="outlined"
                aria-label="upload file - Staff ID"
                component="label"
                loading={uploading["Staff ID"] === "ongoing" ? true : false}
                loadingPosition="end"
                disabled={uploading["Staff ID"] === "ongoing" ? true : false}
              >
                <Typography variant="body2">upload file - Staff ID</Typography>
                <input hidden accept="*" onInput={handleFileSelect} name="Staff ID" type="file" />
                {uploading["Staff ID"] === "done" ? <CheckIcon /> : uploading["Staff ID"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton>
              
            </Box>
          </Box>
          <Box
            className="event days"
            sx={{
              width: {xs:"90%", md:"50%"},
              border: "1px dashed #000",
              margin: 3,
              padding: {xs:3, md: 3},
              display:"flex",
              gap:1,
              flexDirection: "column",
            }}
          >
            <Typography variant="body1">Event Days Accreditation</Typography>
            <Typography variant="body1">Use this template for staff or brand official accreditation on event days. Staff badges will be restricted to staff gate entry.</Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <a href="https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FAccreditation%2FSOLEDXB%202022_EVENT%20DAYS%20Accredition%20Form.xlsx?alt=media&token=6db55f30-4ff9-4aa1-8ec5-82148c52def2" target="_blank" rel="opener" style={{textDecoration:"none"}}>
               <Button
                style={{color:"#000", borderColor:"#000", borderRadius:0}}
                variant="outlined"
                aria-label="Download Template"
                component="label"
              >
                <Typography variant="body2">Download Template</Typography>
                <DownloadIcon />
              </Button>
              </a>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              
              <LoadingButton
                style={{color:"#000", borderColor:"#000", borderRadius:0, width:"270px", justifyContent:"flex-start"}}
                variant="outlined"
                aria-label="upload file"
                component="label"
                loading={uploading["Event days"] === "ongoing" ? true : false}
                loadingPosition="end"
                disabled={uploading["Event days"] === "ongoing" ? true : false}
              >
                <Typography variant="body2">Upload File - Event days</Typography>
                <input hidden accept=".xls, .xlsx" onInput={handleFileSelect} name="Event days" type="file" />
                {uploading["Event days"] === "done" ? <CheckIcon /> : uploading["Event days"] === "ongoing" ? "" : <FileUploadIcon />}
              </LoadingButton>
              
            </Box>
          </Box>
        </Box>
        <Snackbar
        anchorOrigin={{vertical , horizontal}} 
        open={openLoginMessage}
        onClose={handleClose}
        autoHideDuration={6000}
        key={"successful message"}
      >
        <Alert onClose={handleClose} severity={notificationType} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default Accreditation;
