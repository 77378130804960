import {createContext, useState, useEffect} from "react";



const UserContext = createContext();

export function UserProvider({children}){
    const [user , setUser] = useState(null);

    useEffect(()=>{
        const profile = JSON.parse(localStorage.getItem('profile'));
        if(profile)
        setUser(profile?.user);
        else
        setUser(null);
    },[]);

    

    return(
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;