import {createContext, useState, useEffect} from "react";



const CartContext = createContext();

export function CartProvider({children}){
    const [cart , setCart] = useState({});
    const [cartSize , setCartSize] = useState(0);
    const [totalCost , setTotalCost] = useState(0);

    useEffect(()=>{
        let length = Object?.keys(cart)?.length;
        setCartSize(length);
        setTotalCost(0);
        Object.keys(cart).map(key=> {
            if(cart[key]?.cost){
                setTotalCost((prevCost)=> prevCost + cart[key].cost)
            }
            else {
                Object.keys(cart[key]).map(k=>{
                    if(cart[key][k].cost){
                        setTotalCost((prevCost)=> prevCost + cart[key][k].cost)
                    }});
            }
            // if(cart[key].length){
            //     cart[key].map(item=>{
            //         setTotalCost((prevCost) => prevCost+ item?.cost)
            //     })
            // }
            // else{
            //     if(cart[key].cost){
            //         setTotalCost((prevCost)=> prevCost + cart[key].cost)
            //     }
            // }
        })
    },[cart])
  

    

    return(
        <CartContext.Provider value={{cart, setCart ,totalCost, cartSize}}>
            {children}
        </CartContext.Provider>
    )
}

export default CartContext;